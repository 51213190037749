import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
import {Helmet} from "react-helmet";
import { Fade, Zoom, } from 'react-reveal';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const contentSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down'>PLAY OR LEARN</h4>
        <h1 data-aos='fade-up'>
          Futsal (Mini-Football) -  Best Infrastructure. Professional Coaching.
        </h1>
        <div class='btarea' data-aos='zoom-in'></div>
      </div>
    </div>
    <div class='aboutarea ' data-aos='zoom-in'>
      <div class='aboutbox '>
        <div class='boxes '>
          <svg>
            <use href='#park'></use>
          </svg>
          <h5> 5 Cities</h5>
        </div>
        <div class='boxes '>
          <svg viewBox='0 0 55.558 47.814'>
            <use href='#stadium'></use>
          </svg>
          <h5> Best in Class Infra</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#football'></use>
          </svg>
          <h5> FIFA certified Turfs </h5>
        </div>
      </div>
    </div>
  </>
);

const mainSection = (
  <>
    <main>
    <section class='partnersec badmintondeco'>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/gaurs_futsal_0.png' alt='futsal turf' />
                    <img src='images/gallery/gaurs_futsal_1.png' alt='football academy near me' />
                    <img src='images/gallery/gaurs_futsal_2.png' alt='football coaching' />
                   
                  </Carousel></Fade>

              </div>
            </div>
            <div class='contentarea '>
              <Zoom>
                <h4 > PlayAll</h4>
                <h2 >PlayAll Box-Cricket Arena - Adriel High School, Sec 24, Rohini</h2>
                <p>With a 7-Vs-7 FIFA approved 50 mm <b>Futsal Turf </b>, PlayAll is one of the best <b>Football Ground</b> in Rohini. With Amenities like Ample Parking, Cafeteria, and Sports Shop, we ensure that your Football experience is hassel free and awesome. </p></Zoom>
                <span class='address'>
                Address - Adriel High by Pratap International School, Pocket-2, Sector-24, Rohini, New Delhi, Delhi, 110085| Contact - 011-411-69922
              </span>
              <div class='btarea mb-3'>
                <a
                  href='https://maps.app.goo.gl/t2J84Pgn9TzssBvf8'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >Play All</h4>
                <h2 >PlayAll Football Arena - Abhinav School Pitampura</h2>
                <p>With a 6-Vs-6 FIFA approved 50 mm <b>Futsal Turf </b>, Play All is one of the best <b>Football Ground</b> in Pitampura. With Amenities like Ample Parking, Shower, Cafeteria, and Sports Shop, we ensure that your Football experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address - Abhinav School, Pitampura Marg, Block CU, Ranikhet, Pitampura, New Delhi, Delhi, 110034| Contact - 01141170771
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://maps.app.goo.gl/cuahSyZtBxvvxaFV7'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>

              
            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                  <img src='images/gallery/faridabad_futsal_0.png' alt='futsal turf' />
                    <img src='images/gallery/faridabad_futsal_1.png' alt='football academy near me' />
                    <img src='images/gallery/faridabad_futsal_2.png' alt='football coaching' />
                    <img src='images/gallery/faridabad_futsal_3.png' alt='football ground near me' />
                    <img src='images/gallery/faridabad_futsal_4.png' alt='football ground ' />

                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='partnersec badmintondeco'>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/gaurs_futsal_0.png' alt='futsal turf' />
                    <img src='images/gallery/gaurs_futsal_1.png' alt='football academy near me' />
                    <img src='images/gallery/gaurs_futsal_2.png' alt='football coaching' />
                   
                  </Carousel></Fade>

              </div>
            </div>
            <div class='contentarea '>
              <Zoom>
                <h4 > PlayAll</h4>
                <h2 >Gaur City - Greater Noida</h2>
                <p>With a 7-Vs-7 FIFA approved 50 mm <b>Futsal Turf </b>, PlayAll is one of the best <b>Football Ground</b> in Noida. With Amenities like Ample Parking, Cafeteria, and Sports Shop, we ensure that your Football experience is hassel free and awesome. </p></Zoom>
                <span class='address'>
                Address - Gaur City Sports Complex, E Block, Gaur City 1, Sector 4, Greater Noida, Uttar Pradesh 201009| Contact - 011-411-87822
              </span>
              <div class='btarea mb-3'>
                <a
                  href='https://goo.gl/maps/XRjs1h6FZjYUoPtv7'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >Play All</h4>
                <h2 >Faridabad</h2>
                <p>With a 6-Vs-6 FIFA approved 50 mm <b>Futsal Turf </b>, Play All is one of the best <b>Football Ground</b> in Faridabad. With Amenities like Ample Parking, Shower, Cafeteria, and Sports Shop, we ensure that your Football experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address - Near Sai Mandir, Sector 86, Faridabad, Haryana 121002| Contact - 01141187780
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://g.page/play-all-sports-faridabad?share'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>

              
            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                  <img src='images/gallery/faridabad_futsal_0.png' alt='futsal turf' />
                    <img src='images/gallery/faridabad_futsal_1.png' alt='football academy near me' />
                    <img src='images/gallery/faridabad_futsal_2.png' alt='football coaching' />
                    <img src='images/gallery/faridabad_futsal_3.png' alt='football ground near me' />
                    <img src='images/gallery/faridabad_futsal_4.png' alt='football ground ' />

                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='partnersec badmintondeco'>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/gurgaon_futsal_0.png' alt='futsal turf' />
                    <img src='images/gallery/gurgaon_futsal_1.png' alt='football academy near me' />
                    <img src='images/gallery/gurgaon_futsal_2.png' alt='football coaching' />
                    <img src='images/gallery/gurgaon_futsal_3.png' alt='football ground near me' />

                  </Carousel></Fade>

              </div>
            </div>
            <div class='contentarea '>
              <Zoom>
                <h4 > PlayAll</h4>
                <h2 >Gurgaon</h2>
                <p>With a 7-Vs-7 FIFA approved 50 mm <b>Futsal Turf </b>, FaceOff @Play All is one of the best <b>Football Ground</b> in Gurugram. With Amenities like Ample Parking, Shower, Cafeteria, and Sports Shop, we ensure that your Football experience is hassel free and awesome. </p></Zoom>
              <span class='address'>
                Address - CRPF Road, Opposite Heritage School, Sector 62, Gurgaon | Contact - 01141179906
              </span>
              <div class='btarea mb-3'>


                <a href='https://goo.gl/maps/ehmfU6Qtyw82  ' class='btwhite1'
                  target="_blank"
                  rel="noreferrer"
                  >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Zoom >Directions</Zoom>
                </a>
              </div>


              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >Play All</h4>
                <h2 >Alaknanda Delhi</h2>
                <p>With a 6-Vs-6 FIFA approved 50 mm <b>Futsal Turf </b>, Play All is one of the best <b>Football Ground</b> in Delhi. With Amenities like Ample Parking, and online booking, we ensure that your Football experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
              Address - Kalka Public School, Kalka Public School Rd, Alaknanda, New Delhi, Delhi| Contact - 011-411-87784
              </span>

              <div class='btarea mb-3'>
              <a
                  href='https://goo.gl/maps/hdrrcpF2sTk5EvCE6'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>

              
            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                  <img src='images/gallery/kalka_public_school_1.png' alt='futsal pitch near me' />
                  <img src='images/gallery/kalka_public_school_2.png' alt='football turf near me' />
                  <img src='images/gallery/kalka_public_school_3.png' alt='turf in south delhi' />
                 
                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='partnersec badmintondeco'>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                 
                    <img src='images/gallery/orbitMall_futsal_0.png' alt='futsal turf' />
                    <img src='images/gallery/orbitMall_futsal_1.png' alt='football academy near me' />
                    <img src='images/gallery/orbitMall_futsal_2.png' alt='football coaching' />
                    <img src='images/gallery/orbitMall_futsal_3.png' alt='football ground near me' />
                 
                  </Carousel></Fade>

              </div>
            </div>
            <div class='contentarea '>
              <Zoom>
                <h4 > PlayAll</h4>
                <h2 >Orbit Mall - Jaipur </h2>
               <p>With a 7-Vs-7 FIFA approved 50 mm <b>Futsal Turf </b>, FaceOff @Play All is one of the best <b>Football Ground</b> in Gurugram. With Amenities like Ample Parking, Shower, Cafeteria, and Sports Shop, we ensure that your Football experience is hassel free and awesome. </p></Zoom>
               <span class='address'>
              Address - Civil Lines Metro Station, Orbit Mall, Ajmer Rd, Madrampur, Civil Lines, Jaipur, Rajasthan 302006 | Contact - 	011-411-86654
              </span>
              <div class='btarea mb-3'>


              <a href='https://goo.gl/maps/4VAo7j8ZzX9rBojQ9' class='btwhite1'
                  target="_blank" rel="noreferrer">

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Zoom >Directions</Zoom>
                </a>
              </div>


              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class=' techsection footballdeco'>
        <div class='container'>
          <div class='headarea'>
            <h4 class=' sectiontitle '>
              PlayAll
            </h4>
            <Fade bottom cascade><h2 class=' sectionhead '>
              One-Stop-Destination for Futsal Lovers
            </h2></Fade>
          </div>
          <div class='techarea sportsarea'>
            <div class='sportsbox '>
              <div class='iconarea'>
              <svg viewBox="0 0 55.558 47.814">
            <use href="#data"></use>
          </svg>
              </div>
              <Zoom><h4 >Pay & Play | Memberships</h4></Zoom>
              <span>BOOK YOUR TURF IN ADVANCE, SIT BACK & RELAX!</span>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#football '></use>
                </svg>
              </div>
              <Zoom><h4 >Coaching</h4></Zoom>
              <span>
              CURRICULUM-DRIVEN COACHING BY PROFESSIONAL COACHES.

              </span>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
              <svg viewBox='0 0 55.558 47.814'>
            <use href='#stadium'></use>
          </svg>
              </div>
              <Zoom><h4 >Best-in-class Infra</h4></Zoom>
              <span>
                FIFA APPROVED TURF, INTERNATIONAL LIGHTING, 20 FT HEIGHT
              </span>
            </div>
           
          </div>
        </div>
      </section>


    </main>
  </>
);

const Futsal = () => {
  return (
    <>

<Helmet>
            <title>Futsal - Football Ground, Futsal Turf, and mini football Ground</title>
            <meta name="description" content="Play all offers best Futsal Turf and Football Ground. In Noida we have 2 football ground near sector 11. In Faridabad we have 1 football ground near sector 86. In Gurgoan we have 1 football ground near sector 62." />
        </Helmet>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}
        <div class='headbanner membershipbg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"football-ground"} />
            {contentSection}
          </div>
        </div>
        {mainSection}
        {footerSection}
      </div>
    </>
  );
};

export default Futsal;
