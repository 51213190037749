import React from 'react';

// import { isIOS, isAndroid } from 'react-device-detect';



const AppRedirect = () => {
    console.log("AppRedirect");
    const iosUrl = 'https://apps.apple.com/us/app/play-all/id6480510323'; // replace with your iOS app URL
    const androidUrl = 'https://play.google.com/store/apps/details?id=com.playallrn'; // replace with your Android app URL
  
    // const source = window?.navigator?.userAgentData.platform;

    const userAgent = window.navigator.userAgent;
    console.log("userAgent", userAgent);
    let url = androidUrl
    if (/android/i.test(userAgent)) {
        url = androidUrl
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        url = iosUrl        
    } else if (/Win/i.test(userAgent)) {
        url = androidUrl

    } else if (/Mac/i.test(userAgent)) {
        url = iosUrl
    } else if (/Linux/i.test(userAgent)) {
        url = androidUrl

    } else {
        url = androidUrl

    }

       window.location = url;
    //    window.open('url', '_blank');
    return <p> Redirecting to Play store or App store</p>;
    // if (source === 'Android') {
    //     window.location.href = androidUrl;
    //     return null;
    // } else {
    //     //redirect user to ios app
    //     window.location.href = linklyURL;
    //     return null;
    // }
};

export default AppRedirect;