import React from 'react';
import { Button, Table,Divider } from "semantic-ui-react";
import ModalPortal from '../../generic/ModalPortal';
import { colors } from '@material-ui/core';
const StudentAttendanceHistory = (props) => {
    const { showModal, studentData } = props;

    const modalContent = (
        <div>
            <Table celled padded>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <div><b>Student Name</b></div>
                        </Table.Cell>
                        <Table.Cell>
                            <div>{studentData.studentName}</div>
                        </Table.Cell>
                        <Table.Cell>
                            <div><b>Enrollment ID</b></div>
                        </Table.Cell>
                        <Table.Cell>
                            <div>
                                {studentData.enrollmentId}
                            </div>
                        </Table.Cell>

                    </Table.Row>
                </Table.Body>
            </Table>
            <Divider horizontal>
                <span style={{ color: colors.blue["400"] }}>Attendance History</span>
            </Divider>

            <Table celled padded>
                <Table.Header>
                    <Table.HeaderCell>
                        Date
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Time
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Marked By
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Attendance
                    </Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {studentData.attendanceRecord.reverse().map((record, i) => {
                       return( <Table.Row key={i}>
                            <Table.Cell>
                                {new Date(record.createdAt.seconds * 1000).toLocaleDateString()}
                            </Table.Cell>
                            <Table.Cell>
                                {new Date(record.createdAt.seconds * 1000).toLocaleTimeString()}
                            </Table.Cell>
                            <Table.Cell>
                                {record.createdBy.userName}
                            </Table.Cell>
                            <Table.Cell>
                                {record.attendance ? <p>Marked</p> : <p>Unmarked</p>}
                            </Table.Cell>
                        </Table.Row>);
                    })}
                </Table.Body>
            </Table>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
            </React.Fragment>
        );
    };
    return (
        <>
            <ModalPortal
                title='Attendance History'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>
    )
}

export default StudentAttendanceHistory;