import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
import {Helmet} from "react-helmet";

import { Fade, Zoom } from 'react-reveal';
const contentSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down' >
          PLAY BETTER
        </h4>
        <h1 data-aos='fade-up'>
          We Are Redefining Sports. Experience The Difference.
        </h1>
        <div class='btarea' data-aos='zoom-in'>
          <a href='/bookNow'>
            {" "}
            <button class='btwhite mr-4'>Book Now</button>
          </a>
        </div>
      </div>
    </div>
    <div class='aboutarea ' data-aos='zoom-in'>
      <div class='aboutbox '>
        <div class='boxes '>
          <svg viewBox='0 0 55.558 47.814'>
            <use href='#stadium'></use>
          </svg>
          <h5> Best in Class Infra</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#park'></use>
          </svg>
          <h5> 6 Cities</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#football'></use>
          </svg>
          <h5> 5 Sports</h5>
        </div>
      </div>
    </div>
  </>
);

const mainSection = (
  <>
    <main>
      <section  class='partnersec badmintondeco'>
        <div class='container'>
          <div class='innerbx'>
            <div
              class=' rightbx startplayarea d-flex justify-content-center'
            >
              <div class='startplaybox'>
                <Fade left><img src='images/payplay.png' alt="" /></Fade>

                <Zoom><span>
                  Join the family of 34,000+ happy customers and see what it
                  feels to play like never before.
                </span></Zoom>
              </div>
            </div>

            <div class='contentarea '>
              <h4 >PAY & PLAY </h4>
             <Fade right> <h2 >
                Enjoy Your Favorite Sport Like Never Before
              </h2></Fade>
              <Zoom><span>
                With our presence in 6 major cities, we bring you modern
                facilities at prime locations. And we take your safety seriously
                with first-aid kits and emergency action plans ready wherever
                you need them.
              </span></Zoom>

              <div class='btarea'>
                <button class='btorng'>Call us @ 9310096119</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec cricketdeco'>
        <div class='container'>
          <div class='innerbx'>
            <div class='contentarea '>
              <h4 >INCREDIBLE MEMBERSHIP PACKAGES </h4>
              <Fade left><h2 >So That Nobody Lives Without Playing</h2></Fade>
              <Zoom><span>
                To help you enjoy your favorite sport without breaking the bank,
                we offer fixed and flexible membership packages. Get the ‘Fixed
                Package’ to book one badminton court for a monthly fixed slot,
                or go for the ‘Flexible Package’ and buy prepaid credits to
                redeem as and when you want.
              </span></Zoom>
              <div class='btarea'>
                <button class='btorng'>Call us @ 9310096119</button>
              </div>
            </div>
            <div
              class=' rightbx startplayarea1 d-flex justify-content-center'
            >
              <div class='startplaybox'>
              <Fade right>  <img src='images/member.png' alt=""/></Fade>

                <Zoom><span>
                  All our packages can be easily booked offline by visiting our
                  site or through our booking partners. Excellence delivered.
                </span></Zoom>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class=' techsection '>
        <div class='container'>
          <div class='headarea'>
            <h4 class=' sectiontitle '>
              HOW DO WE{" "}
            </h4>
            <Fade top><h2 class=' sectionhead '>
              Ensure The Best Sport Experience?{" "}
            </h2></Fade>
            <Zoom><span>
              We do our best to help you play the sport that matters the most to
              you.
            </span></Zoom>
          </div>
          <div class='techarea sportsarea'>
            <div class='sportsbox box'>
              <div class='iconarea' >
                <svg>
                  <use href='#trophy'></use>
                </svg>
              </div>
              <h4 >Hassle-Free Play</h4>
              <Zoom> <span>
                Pay and play or take a membership. Either way, your satisfaction
                is guaranteed.
              </span></Zoom>
            </div>
            <div class='sportsbox '>
              <div class='iconarea' >
                <svg>
                  <use href='#voucher'></use>
                </svg>
              </div>
              <h4 >Discounted Packages</h4>
              <Zoom><span>
                Our packages are discounted to help you make the most of our
                mutual love: sport.
              </span></Zoom>
            </div>
            <div class='sportsbox box'>
              <div class='iconarea'>
                <svg>
                  <use href='#shower'></use>
                </svg>
              </div>
              <h4 >Shower Option</h4>
              <Zoom><span>
                Take a shower to freshen up after a heated session of your
                favorite sport. Presently available at our Gurgaon and Faridabad
                facilities.
              </span></Zoom>
            </div>
          </div>
        </div>
      </section>
    </main>{" "}
  </>
);

const MembershipsPage = () => {
  return (
    <>
    <Helmet>
            <title>Memberships</title>
            <meta name="description" content="Play all offers monthly membership for badminton courts and football ground." />
        </Helmet>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}
        <div class='headbanner membershipbg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"membership"} />
            {contentSection}
          </div>
        </div>
        {mainSection}
        {footerSection}
      </div>
    </>
  );
};

export default MembershipsPage;
