import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
import {Helmet} from "react-helmet";

import { Fade, Zoom } from 'react-reveal';
const contentSection = (
  <>
    <div class="dispfbanner">
      <div class="bannerarea">

        <h4 data-aos="zoom-in-down">SHAPING SPORTS </h4>
        <h1 data-aos="fade-up">With Ultra-Modern Digital Products</h1>
        <div class="btarea" data-aos="zoom-in">
          <button class="btwhite mr-4">Email - tech@playall.in</button>

        </div>
      </div>
    </div>
    <div class="aboutarea " data-aos="zoom-in">

      <div class="aboutbox ">
        <div class="boxes ">
          <svg viewBox="0 0 55.558 47.814">
            <use href="#data"></use>
          </svg>
          <h5> Advanced Tech</h5>

        </div>
        <div class="boxes ">
          <svg>
            <use href="#lightbulb"></use>
          </svg>
          <h5> Innovation & Expertise</h5>

        </div>
        <div class="boxes ">
          <svg>
            <use href="#support"></use>
          </svg>
          <h5> Happy Clients</h5>

        </div>


      </div>

    </div>
  </>
);

const mainSection = (
  <>
    <main>
      <section class="partnersec badmintondeco">
        <div class="container">
          <div class="innerbx">
            <div class=" rightbx startplayarea d-flex justify-content-center">
              <div class="startplaybox">
                <Fade left><img class="techimgs" alt="visitors" src="images/softwareimg.png" /></Fade>
                <Zoom><h5 class="pr-4 pl-4">From Booking Management to Customer Relationship Management, our tools help us serve you as your go-to sports software partner at every step of the way. </h5></Zoom>
              </div>
            </div>

            <div class="contentarea ">
              <h4 >SOFTWARE DEVELOPMENT</h4>
              <Fade right> <h2 >Sports Complex Management Software</h2></Fade>

              <Zoom> <span>Driven by our zeal to innovate constantly, we deliver cutting-edge sports complex management software. And we bring features you need the most with detailed Reporting and Analytics to help you grow. </span></Zoom>
              <div class="btarea">

                <button class="btorng">Email - tech@playall.in</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="partnersec ">
        <div class="container">
          <div class="innerbx">


            <div class="contentarea ">
              <h4 >App Development </h4>
              <Fade left><h2 >Mobile Application Design and Development
              </h2></Fade>

              <Zoom><span>Let’s take your sports facility to your customers’ fingertips. Our iOS and Android booking applications are all you need to scale your revenue digitally and provide a personalized experience to your customers. </span>
              </Zoom> <div class="btarea">

                <button class="btorng">Email - tech@playall.in</button>
              </div>
            </div>
            <div class=" rightbx startplayarea1 d-flex justify-content-center">
              <div class="startplaybox">
                <Fade right><img class="techimgs" alt="profile" src="images/appbg.png" />
                </Fade>
                <Zoom> <h5 class="pr-4 pl-4">Create tailored membership packages, push notifications, and manage your customers better – all with a single app. </h5>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
      </section>

     

      <section class=" techsection ">
<div class="container">
    <div class="headarea">
        <h4 class=" sectiontitle ">HOW DO WE
        </h4>
        <Fade top><h2 class=" sectionhead fadeInDown wow">Help You Leverage Technology? </h2></Fade>
        <Zoom><span>We believe in expanding the world of sports with technology that enables you to do more and do better.</span></Zoom>
    </div>
    <div class="techarea sportsarea">

        <div class="techtypebox bx1 ">

            <Fade top><h1 style={{color: 'white'}}>UI/UX</h1></Fade>
            <span>With an easy-to-use interface, our software and digital tools let you elevate the user experience

               </span>
        </div>
        <div class="techtypebox bx2 ">

        <Fade top><h1 style={{color: 'white'}}>Front-End Development</h1></Fade>
            <span>Our front-end development solutions enable intuitive interactions to help you ramp up your operations
               </span>
        </div>
        <div class="techtypebox bx3 ">

        <Fade top><h1 style={{color: 'white'}}>Back-End Development</h1></Fade>
            <span>With our back-end development solutions, we build digital products that seamlessly work with your systems
               </span>
        </div>
        <div class="techtypebox bx4 ">

        <Fade top><h1 style={{color: 'white'}}>iOS/Android Apps</h1></Fade>
            <span>We develop intelligent iOS and Android applications, from idea to the end product and beyond
               </span>
        </div>
        <div class="techtypebox bx5 ">

        <Fade top><h1 style={{color: 'white'}}>CRM System with IVR</h1></Fade>
            <span>Streamline your operations with a CRM system with integrated IVR to effortlessly capture inquiries and track conversions 
               </span>
        </div>
        <div class="techtypebox bx6 ">

        <Fade top><h1 style={{color: 'white'}}>Booking Management System</h1></Fade>
            <span>Our booking management tool helps you integrate your sports software with major online booking sites without any hassles
               </span>
        </div>
    </div>

</div>
</section>
    </main>
  </>
);

const TechPage = () => {
  return (
    <>

<Helmet>
            <title>Sports Software</title>
            <meta name="description" content="Play all offers Sports Tech solutions like Software as a service and App development to it's customer." />
        </Helmet>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}
        <div class='headbanner techbg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"tech"} />
            {contentSection}
          </div>
        </div>
        {mainSection}
        {footerSection}
      </div>
    </>
  );
};

export default TechPage;
