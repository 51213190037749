import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
import { Helmet } from "react-helmet";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const contentSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down'>Policies</h4>
        <h1 data-aos='fade-up'>
          Privacy Policy, Terms & Conditions, & Cancellation Policy
        </h1>
        <div class='btarea' data-aos='zoom-in'></div>
      </div>
    </div>

  </>
);

const mainSection = (
  <>
    <main>

      <section class='image-section'>
        <img src='images/gallery/privacyPolicy.jpg' alt='privacy policy playall' style={{ width: '100%' }} />
      </section>

      <section class='image-section'>
        <img src='images/gallery/termsAndConditions.png' alt='terms and condition playall' style={{ width: '100%' }} />
      </section>

      <section class='image-section'>
        <img src='images/gallery/cancellationPolicy.png' alt='cancellation policy' style={{ width: '100%' }} />
      </section>



    </main>
  </>
);

const Policies = () => {
  return (
    <>

      <Helmet>
        <title>Policies</title>
        <meta name="description" content="Privacy Policy, Terms & Conditions, & Cancellation Policy" />
      </Helmet>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}
        <div class='headbanner pickleBallBg' data-aos='fade'>
          <div class='container '>
            <NavSection />
            {contentSection}
          </div>
        </div>
        {mainSection}
        {footerSection}
      </div>
    </>
  );
};

export default Policies;
