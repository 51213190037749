import React, { useState, useContext, useRef, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import GenericLoader from '../generic/GenericLoader';
import { AppContext } from '../../AppContext';
import moment from 'moment';
import ExportToExcel from '../generic/ExportToExcel';
import { DatePicker } from '@material-ui/pickers';
// import Toast from "../../generic/Toast";
import {
    // Button,
    // Icon,
    Table,
  } from "semantic-ui-react";
import { getMonthlyDataBeforeCurrentMonth } from '../../apiclients/ReportsApiClient';
const CustomerReportsData = () => {

    const {
        selectedFacility,
        selectedArena,
        arenaData,
        user
    } = useContext(AppContext);

    const sports = useRef([]);
    const [selectedSport, setSelectedSport] = useState();
    const [isLoading, setisLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [loader, setLoader] = useState(false);

    const [customerData, setCustomerData] = useState([]);
    const fileName = "Custom Report Data" + moment().format('MMMM Do YYYY');
    const sheetName = moment().format('MMMM Do YYYY, h_mm a');
    const tableHeader = [
        { label: "S.No.", value: "serialNumber" },
        { label: "Customer Name", value: "customerName" },
        { label: "Customer Number", value: "customerNumber" },
        { label: "Number of Transctions", value: "totalTransctions" },
        { label: "Total Amount Paid", value: "totalAmoundPaid" }
    ];
    const sheets = [
        {
            name: sheetName,
            columns: tableHeader,
            dataSet: customerData,
        },
    ];



    const arenaIndex = user.facilityIncharge.findIndex(x => x.arenaId === selectedArena.arenaId);







    useEffect(() => {
        if (selectedArena && arenaData[selectedArena.arenaId]) {
            let dropdownSportsData = arenaData[selectedArena.arenaId]?.sports.map(
                (x, index) => ({
                    key: x.sportId,
                    value: x.sportId,
                    text: x.sportName,
                })
            );
            if (user.facilityIncharge[arenaIndex].sportIds) { // that means the user access is restricted to a sport
                const listOfSports = user.facilityIncharge[arenaIndex].sportIds;
                dropdownSportsData = dropdownSportsData.filter(x => listOfSports.includes(x.key))
            }
            sports.current = dropdownSportsData;
            setSelectedSport(dropdownSportsData[0]);
            
        }
    }, [selectedFacility, selectedArena.arenaId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedSport) {
            getMonthlyDataBeforeCurrentMonthApi(selectedFacility, selectedArena.arenaId, selectedSport.value);
        }
    }, [selectedSport,selectedDate,selectedArena.arenaId]);

    const changeSport = (event, { value }) => {

        const data = sports.current.find((facility) => facility.value === value);
        setSelectedSport(data);
       
    };

    const getMonthlyDataBeforeCurrentMonthApi = async (facilityId, arenaId, sportId) => {
          console.log("selectedArena.arenaId",selectedArena.arenaId)
        try {
            setisLoading(true)
            let startDate = moment(selectedDate).format('YYYY-MM-DD');
            let response = await getMonthlyDataBeforeCurrentMonth(facilityId, arenaId, sportId,startDate);
            setisLoading(false)
            setCustomerData(response?.data)
        } catch (error) {
            setisLoading(false)
            console.log("error", error)
        }

    }

    const dateChange = (date) => {
       
        setSelectedDate(date);
     
        
    }



    return isLoading ? <GenericLoader /> :
        (
        <div style={{ padding: "16px" }}>
            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>
                        Customer Data </span>
                    </div>

                    <div style={{ alignItems: "center" }}>
                        <div style={{ fontSize: "14px" }}>

                            <DatePicker
                                autoOk
                                // disableFuture
                                variant='inline'
                                value={selectedDate}
                                placeholder='Select Date...'
                                onChange={dateChange}
                                format='DD MMM yyyy'
                            />

                        </div>
                    </div>

                    <div style={{ alignItems: "center" }}>
                        <div style={{ fontSize: "14px" }}>
                            <span style={{ marginRight: "10px" }}>
                                <ExportToExcel disabled={customerData.length <= 0} sheets={sheets}
                                    fileName={fileName}
                                    buttonName="Download Report"
                                />
                            </span>
                            <Dropdown
                                onChange={changeSport}
                                placeholder='Select...'
                                openOnFocus
                                selection
                                value={selectedSport && selectedSport.value}
                                options={sports.current}
                            />

                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div>
            This reports shows the data of customers who have not booked in the last 30 days from the selected days, however they made more than 4 booking between last 60 to 30 days
            </div>
            {loader ? (
        <GenericLoader />
      ) : (
        <div style={{ paddingTop: "16px" }}>
         
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>S.No</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Number</Table.HeaderCell>
                <Table.HeaderCell>Total Bookings</Table.HeaderCell>
                <Table.HeaderCell>Total Amount</Table.HeaderCell>
               
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {customerData.map((x, index) => {
                // const x = y.data;
                return (
                  <Table.Row key={x.id} >
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{x.customerName}</Table.Cell>
                    <Table.Cell>{x.customerNumber}</Table.Cell>
                    <Table.Cell>{x.totalTransctions}</Table.Cell>
                    <Table.Cell>{x.totalAmoundPaid}</Table.Cell>
                  </Table.Row>

                );
              })}
            </Table.Body>
          </Table>
           

 

        </div>

      )}
        </div>
        );
}

export default CustomerReportsData;