import React, { useEffect, useContext, useState } from "react";
import {   Button, Icon, Table, Tab, } from 'semantic-ui-react';

import { colors } from "@material-ui/core";
import "../dashboard/style.scss";

import { AppContext } from "../../AppContext";
import GenericLoader from "../generic/GenericLoader";
import {
    
    getUserWalletsDetailsWeb
} from "../../apiclients/WalletApiClient";
import Toast from '../generic/Toast';

 
import WalletTransaction from "./WalletTransaction";
const WalletUsuage = (props) => {
    const { history } = props;
    const context = useContext(AppContext);
    // const [reload, setReload] = useState(true);
    const [packages, setPackages] = useState([]);


    const [addModal, setAddModal] = useState(false); // to show add modal



    const [isLoading, setisLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageContent, setMessageContent] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);
    const types = ['active', 'expired',];
    const [mobile, setMobile] = useState("");
    const [selectedPackage, setSelectedPackage] = useState({});

    useEffect(() => {
        if (mobile) {
            getUserWalletsDetailsWebApi();
        }
    }, [activeIndex]);

    const getUserWalletsDetailsWebApi = () => {
        setisLoading(true);
        getUserWalletsDetailsWeb(
            context.selectedFacility,
            mobile,
            activeIndex === 0 ? false : true,
            context.selectedArena.arenaId,
        ).then((response) => {
            const pacakgeData = response.data.walletData;
            console.log(pacakgeData, "pacakgeData");
            setPackages(pacakgeData);
            setisLoading(false);
            // setReload(false);
        }).catch((error) => {
            console.log(error);
            setisLoading(false);
        });
    }

const handlePackageClick = (packageData) => {
    packageData.mobile = mobile;
    setSelectedPackage(packageData);
    setAddModal(true);
}











    const panes = [
        {
            menuItem: "Active Wallets",
            render: () => (
                <Tab.Pane >
                    <ActiveLeadDataTable
                        packages={packages}
                        // setReload={setReload}
                        history={history}
                        setMessage={setMessageContent}
                        isLoading={setisLoading}
                        types={types[activeIndex]}
                        setAddModal={(item) =>  {handlePackageClick(item)}}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Expired Wallets",
            render: () => (
                <Tab.Pane  >
                    <ActiveLeadDataTable
                        packages={packages}
                        // setReload={setReload}
                        history={history}
                        setMessage={setMessageContent}
                        isLoading={setisLoading}
                        types={types[activeIndex]}
                        setAddModal={(item) =>  {handlePackageClick(item)}}
                    />
                </Tab.Pane>
            ),
        },

    ];

    return (
        <div style={{ padding: "16px" }}>

            <div style={{ marginBottom: 20 }} className='dashboard'>

                <Toast
                    showMessage={showMessage}
                    setShowMessage={setShowMessage}
                    message={messageContent.message}
                    messageHeader={messageContent.header}
                    color={messageContent.color}
                />
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>User Wallet</span>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <div style={{ fontSize: "14px" }}>
                            {/* <div style={{backgroundColor:"red"}}> */}

                            <input
                                style={{ padding: "8px", width: "200px", borderRadius: "5px", border: "1px solid #ccc",  position: "relative", /* Ensures input is above other elements */
                                zIndex: 1  }}
                                type='text'
                                // name='search'
                                placeholder='Phone No.'
                                // id='search'
                                onChange={(e) => { setMobile(e.target.value) }}
                                value={mobile}
                            />
                            {/* </div> */}



                            <Button
                                style={{ marginLeft: "20px" }}
                                onClick={() => { getUserWalletsDetailsWebApi() }}

                            >
                                <Icon name={"search"} />
                            </Button>



                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? (
                <GenericLoader />
            ) : (

                <Tab
                    activeIndex={activeIndex}
                    onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
                    renderActiveOnly={true}
                    menu={{
                        color: "orange",
                        pointing: true,
                    }}
                    panes={panes}
                />
            )}

            {addModal ? (
                <WalletTransaction
                    history={history}
                    showModal={setAddModal}
                    // setReload={() => { setReload(true) }}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                    setShowMessage={setShowMessage}
                    selectedPackage={selectedPackage}
                />
            ) : null}





        </div>
    );
};

const ActiveLeadDataTable = ({
    packages,
    // setReload,
    // setMessage,
    // isLoading,
    // history,
    // types,
    setAddModal
}) => {
    // const [showDetails, setShowDetails] = useState(false); // to show student detail modal
    // const [leadId, setLeadId] = useState();
    // const openLeadDetail = (data, id) => () => {
    //     setShowDetails(data);
    //     setLeadId(id);
    // };

    function calculateDaysDifference(targetDate) {
        // Get the current date
        const now = new Date();
    
        // Convert the given date to a Date object
        const givenDate = new Date(targetDate.seconds * 1000 + targetDate.nanoseconds / 1000000);
    
        // Calculate the difference in milliseconds
        const differenceInMillis =  givenDate-now;
    
        // Convert milliseconds to days
        const differenceInDays = Math.floor( differenceInMillis / (1000 * 60 * 60 * 24))+1;
        
        if (differenceInDays < 0) {
            return 0;
        }
        return differenceInDays;
    }

    return (
        <>
            <Table celled padded>
                <Table.Header>
                    <Table.Row>

                        <Table.HeaderCell>S.No</Table.HeaderCell>
                        <Table.HeaderCell>User Name</Table.HeaderCell>
                        <Table.HeaderCell>Mobile</Table.HeaderCell>
                        <Table.HeaderCell>Package Name</Table.HeaderCell>
                        <Table.HeaderCell>Outstandings Credits</Table.HeaderCell>
                        <Table.HeaderCell>Day Remaining</Table.HeaderCell>
                        <Table.HeaderCell>Sport</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {packages.length > 0 ? packages.map((x, index) => {

                        return (
                            <Table.Row key={x.id}>
                                <Table.Cell>{index + 1}</Table.Cell>
                                <Table.Cell>{x?.userName}</Table.Cell>
                                <Table.Cell>{x?.userMobileNumber}</Table.Cell>

                                <Table.Cell>{x?.packageName}</Table.Cell>


                                <Table.Cell>{x.outstandingCredits
                                }</Table.Cell>
                                <Table.Cell>{calculateDaysDifference(x.validTill)} </Table.Cell>
                                <Table.Cell>{x?.sports?.map(x => x.sportName + ', ')}</Table.Cell>

                                <Table.Cell
                                    style={{
                                        flex: 1,
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        display: "flex",
                                    }}
                                >
                                    <Button
                                        style={{
                                            padding: "8px",
                                            fontSize: "12px",
                                            marginBottom: "8px",
                                            backgroundColor: colors.orange["800"],
                                            color: "#fff",
                                        }}
                                        onClick={() => { setAddModal(x); console.log('add modal clicked') }}
                                    >
                                        Details
                                    </Button>

                                </Table.Cell>
                            </Table.Row>
                        );
                    }) : <Table.Row>
                        <Table.Cell colSpan='9' style={{ textAlign: 'center' }}>No data found</Table.Cell>
                    </Table.Row>}
                </Table.Body>
            </Table>

        </>
    );
};

export default WalletUsuage;
