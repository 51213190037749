import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    Button,
    Form,
} from "semantic-ui-react";
import { AppContext } from '../../AppContext';
import ModalPortal from '../generic/ModalPortal';
import { getAllCategories, addCategory } from '../../apiclients/ProductApiClient';
const AddCategory = (props) => {
    const { showModal, setReload, setMessage, isLoading, type } = props;
    const context = useContext(AppContext);
    const rawCategories = useRef([]);
    const [categoryName, setCategoryName] = useState();
    const [subCategories, setSubCategories] = useState();
    // const [categories, setCategories] = useState([]);

    const isSubmitAllowed = categoryName;

    useEffect(() => {
        getAllCategories(type).then((response) => {
            const availableCategories = response.data.data.categories;
            rawCategories.current = availableCategories;
            // const cate = availableCategories.map((x) => {
            //     return { key: x.id, value: x.name, text: x.name }
            // })
            // setCategories(cate);
        });

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Category Name</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={categoryName}
                            placeholder='Category Name'
                            onChange={(evt) => setCategoryName(evt.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Sub Categories</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={subCategories}
                            placeholder='Add multiple subcategories Comma (,) separated'
                            onChange={(evt) => setSubCategories(evt.target.value)}
                        />
                    </Form.Field>

                </Form.Group>


            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button disabled={!isSubmitAllowed} primary onClick={addCategoryFunction}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addCategoryFunction = () => {
         if (rawCategories.current.find(o => o.name === categoryName)) {
            setCategoryName('');
            setSubCategories('');
            showModal(false);
            setMessage({
                header: "Category Already Added",
                message: "Duplicate Category Name are not allowed",
                color: "red",
            });
            setReload(true);
        } else {
            isLoading(true);

            const postBody = {
                type, categoryName, subCategories, createdBy: {
                    userName: context.user.userName,
                    userId: context.user.userId,
                },
            };
            addCategory(
                postBody
            ).then((response) => {

            isLoading(false);
                if (response.status === 200 || response.status === 201) {
                    setMessage({
                        header: "Category Added",
                        message: "Category has been added successfully.",
                        color: "green",
                    });
                } else {
                    setMessage({
                        header: "Error!",
                        message: response.data.error.message,
                        color: "red",
                    });
                }
                setReload(true);
            }).catch((error) => {
                setMessage({
                    header: "Error!",
                    message: error.message,
                    color: "red",
                });
            });
        }
    }
    return (
        <>
            <ModalPortal
                title={'Add '+ type+' Category'}
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default AddCategory;