import React, { useContext, useState, useEffect } from 'react';
// import { colors } from "@material-ui/core";
import {
    Button,
    Dropdown,
    Form,
    // Checkbox,
} from "semantic-ui-react";
import ModalPortal from '../generic/ModalPortal';
import { AppContext } from '../../AppContext';
import { addReceipt } from '../../apiclients/AccountsClient';
import { RECEIPT_CATEGORY } from '../../utils/constants';
import Payment from '../dashboard/Payment';
import { getAllSportsOfArena } from '../../apiclients/BookingApiClient';

const AddReceipt = (props) => {
    const { showModal, setReload, setMessage, isLoading, } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const arenaData = context.arenaData[arenaId];

    // const [amount, setAmount] = useState();
    const [category, setCategory] = useState();
    const [remarks, setRemarks] = useState();
    const [paymentArray, setPaymentArray] = useState([]);
    const [totalPayment, setTotalPayment] = useState();
    const [selectedSport, setSelectedSport] = useState();
    const [listOfSports, setListOfSports] = useState([]);

    const updatePaymentArray = (updatedArray) => {
        const amountPaid = updatedArray.reduce((a, b) => a + Number(b.amount), 0);
        setTotalPayment(amountPaid);
        setPaymentArray(updatedArray);

    };
    const isSubmitAllowed = totalPayment && category && remarks && selectedSport;

    useEffect(() => {
        getAllSportsOfArena(facilityId, arenaId).then(
            (response) => {
                const sports = response.data;
                const dropdownSportsData = sports.map(
                    (x, index) => ({
                        key: x.sportId,
                        value: x.sportId,
                        text: x.sportName,
                    })
                );
                dropdownSportsData.unshift({
                    key: 'generic',
                    value: 'generic',
                    text: 'General',
                });
                setListOfSports(dropdownSportsData);
                setSelectedSport(dropdownSportsData[0].value);
            }
        );
    }, []);

    const changeCategory = (event, { value }) => {
        setCategory(value);
    };

    const changeSports = (event, { value }) => {
        setSelectedSport(value);
    };

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Category</label>
                        <Dropdown
                            onChange={changeCategory}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={category}
                            options={RECEIPT_CATEGORY}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Sports</label>
                        <Dropdown
                            onChange={changeSports}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={selectedSport && selectedSport.value}
                            options={listOfSports}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>

                    <Form.Field  >
                        <label>Amount</label>
                        {/* <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={amount}
                            placeholder='5000'
                            onChange={(evt) => setAmount(evt.target.value)}

                        /> */}
                        <Payment
                            paymentArray={paymentArray}
                            setPaymentArray={updatePaymentArray}
                        />
                    </Form.Field>


                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Details</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={remarks}
                            placeholder='Name, number, team name, etc.'
                            onChange={(evt) => setRemarks(evt.target.value)}

                        />
                    </Form.Field>
                </Form.Group>

            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button disabled={!isSubmitAllowed} primary onClick={addReceipts}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addReceipts = () => {
        const createdBy = {
            userName: context.user.userName,
            userId: context.user.userId,
        };
        showModal(false);
        isLoading(true);
        const postBody = {
            facilityId, arenaId, paymentInfo: paymentArray, category, selectedSport, remarks, createdBy, arenaData
        };

        addReceipt(
            postBody
        ).then((response) => {

            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Receipt Added",
                    message: "Receipt has been added successfully.",
                    color: "green",
                });

            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });
    }
    return (
        <>
            <ModalPortal
                title='Add Receipt'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default AddReceipt;