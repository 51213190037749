import React, { useState, useContext, useRef, useEffect } from 'react';

import { Dropdown, Button } from 'semantic-ui-react';
import { colors } from "@material-ui/core";
import { Pie } from 'react-chartjs-2';
import GenericLoader from '../generic/GenericLoader';
import { AppContext } from '../../AppContext';
import { getAllSportsOfArena } from '../../apiclients/BookingApiClient';
import { getDateWiseTransactionData } from '../../apiclients/AnalyticsClient';
import DatesSelection from '../common/DatesSelection';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid } from '@material-ui/core';

const DateWiseTransactionAnalytics = () => {


    const {
        selectedFacility,
        selectedArena,
        // arenaData,
        // selectedSportDetails,
        // dispatch,
    } = useContext(AppContext);

    const sports = useRef([]);
    const [selectedSport, setSelectedSport] = useState({});
    const [isLoading, setisLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [analyticsData, setAnalyticsData] = useState();
    // const [selectDaysOfWeek, setSelectDaysOfWeek] = useState({ value: 'all', text: 'All Days' });
    var formatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
    });
    // const daysOfWeek = [
    //     { value: 'all', text: 'All Days' },
    //     { value: 'weekdays', text: 'Weekdays' },
    //     { value: 'weekends', text: 'Weekends' },
    // ];// all, weekdays, weekends


    const colorOne = 'rgba(56,121,109)';
    const colorTwo = 'rgba(26,60,54)';
    const colorThree = 'rgba(86,168,226)';
    const colorFour = 'rgba(138,200,183)';
    const colorFive = 'rgba(208,231,214)';
    // const allColors = [colorTwo, colorThree, colorFour, colorFive, colorOne];

    const loadData = () => {
        console.log('the data is ', selectedFacility, selectedArena.arenaId, selectedSport.value, startDate, endDate,);
        setisLoading(true);
        return getDateWiseTransactionData(
            selectedFacility, selectedArena.arenaId, selectedSport.value, startDate, endDate,
        ).then((response) => {
            const analyticsData = response.data;
            setAnalyticsData(analyticsData);
            setisLoading(false);
        }).catch(err => {
            setisLoading(false);
        });
    };


    useEffect(() => {
        if (selectedFacility && selectedArena)
            getAllSportsOfArena(selectedFacility, selectedArena.arenaId).then(
                (response) => {
                    const listOfSports = response.data;
                    const dropdownSportsData = listOfSports.map(
                        (x, index) => ({
                            key: x.sportId,
                            value: x.sportId,
                            text: x.sportName,
                        })
                    );
                    dropdownSportsData.unshift({
                        key: 'arena',
                        value: 'arena',
                        text: 'Arena level',
                    })
                    sports.current = dropdownSportsData;

                    setSelectedSport(dropdownSportsData[0]);

                }
            );
    }, [selectedFacility, selectedArena.arenaId]); // eslint-disable-line react-hooks/exhaustive-deps


    const changeSport = (event, { value }) => {
        console.log(
            "sports data is " +
            JSON.stringify(
                sports.current.find((facility) => facility.value === value)
            )
        );
        const data = sports.current.find((facility) => facility.value === value);
        setSelectedSport(data);

    };


    const changeDate = (isStart) => value => {
        console.log('this')
        if (isStart) {
            setStartDate(value);
        } else {
            setEndDate(value);
        }
    }

    const barColor = colors.orange[700];
    const useStyles = makeStyles({
        root: {
            minWidth: 275,
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    });


    const classes = useStyles();


    return isLoading ? <GenericLoader /> :
        (<div style={{ padding: "16px" }}>
            <div className='dashboard'>Datewise Transaction Analytics</div>
            <div className='selectionFilters'>
                <div>
                    <DatesSelection startDate={startDate} endDate={endDate} changeDate={changeDate}
                        disablePast={false}
                        disableFuture={true} />
                </div>
                <div>
                    <Dropdown
                        onChange={changeSport}
                        placeholder='Select...'
                        openOnFocus
                        selection
                        value={selectedSport && selectedSport.value}
                        options={sports.current}
                    />

                </div>
                <div>
                    <Button
                        onClick={() => loadData()}
                        style={{
                            marginRight: "10px",
                            backgroundColor: colors.orange[700],
                            color: colors.blue[50],
                        }}
                    >
                        Get Data
                    </Button>
                </div>
            </div>
            <hr />

            {analyticsData === undefined ? null :
                <Grid container spacing={9}>
                    <Grid item md={3} spacing={3}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    TYPE OF RECEIPTS
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Net Receipts - </span>{formatter.format(analyticsData.totalReceipts + analyticsData.totalCancellationRefund)} /-
                                </Typography>
                                <Pie
                                    width={30}
                                    height={7}
                                    data={{
                                        labels: [
                                            'Bookings',
                                            'Bulk-Booking',
                                            'Academy',
                                            'Product'
                                        ],
                                        datasets: [{
                                            label: 'My First Dataset',
                                            data: [
                                                analyticsData.transactionType.bookingTransaction,
                                                analyticsData.transactionType.bulkBookingTransaction + analyticsData.transactionType.groupMembershipTransaction + analyticsData.transactionType.individualMembershipTransaction,
                                                analyticsData.transactionType.academyTransaction,
                                                analyticsData.transactionType.productRentalTransaction + analyticsData.transactionType.productSaleTransaction,
                                            ],
                                            backgroundColor: [
                                                colorOne,
                                                colorTwo,
                                                colorThree,
                                                colorFour,
                                            ],
                                            hoverOffset: 4
                                        }]
                                    }}
                                />
                            </CardContent>
                        </Card>

                    </Grid>
                    <Grid item md={3} spacing={3}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Source of Booking
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Total Bookings - </span>{formatter.format(analyticsData.transactionType.bookingTransaction)} /-
                                </Typography>
                                <Pie
                                    width={30}
                                    height={7}
                                    data={{
                                        labels: [
                                            'Play All',
                                            'Hudle',
                                            'Sportido',
                                            'Playo'
                                        ],
                                        datasets: [{
                                            label: 'My First Dataset',
                                            data: [
                                                analyticsData.source.playAllTransaction,
                                                analyticsData.source.hudleTransaction,
                                                analyticsData.source.playoTransaction,
                                                analyticsData.source.sportidoTransaction,
                                            ],
                                            backgroundColor: [
                                                colorOne,
                                                colorTwo,
                                                colorThree,
                                                colorFour,
                                            ],
                                            hoverOffset: 4
                                        }]
                                    }}
                                />
                            </CardContent>
                        </Card>

                    </Grid>

                    <Grid item md={3} spacing={3}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Type of Hudle Bookings
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Hudle Bookings - </span>{formatter.format(analyticsData.source.hudleTransaction)} /-
                                </Typography>
                                <Pie
                                    width={30}
                                    height={7}
                                    data={{
                                        labels: [
                                            'Online',
                                            'Pass',
                                            'Play All Credit',
                                            'Hudle Credit'
                                        ],
                                        datasets: [{
                                            label: 'My First Dataset',
                                            data: [
                                                analyticsData.hudleType.hudleOnlineTransaction,
                                                analyticsData.hudleType.hudlePassTransaction,
                                                analyticsData.hudleType.hudleVenueCreditTrans,
                                                analyticsData.hudleType.hudleCreditTrans,
                                            ],
                                            backgroundColor: [
                                                colorOne,
                                                colorTwo,
                                                colorThree,
                                                colorFour,
                                            ],
                                            hoverOffset: 4
                                        }]
                                    }}
                                />
                            </CardContent>
                        </Card>

                    </Grid>
                </Grid>
            }
        </div>
        );
}

export default DateWiseTransactionAnalytics;