import React from 'react';

function AssetApplelink() {
  // const [assetLinks, setAssetLinks] = useState();
let assetLinks = {
  "applinks": {
      "details": [
           {
             "appIDs": [ "3DX9GUL6MV.com.playall.playallrn" ],
             "paths": [
              "/api/app/*",    // App 1 handles /api/app/*
              "/api/app/BookingSuccess/*",
              "/api/app/ArenaScreen/*" 
            ]
           },
           {
            "appIDs": [ "3DX9GUL6MV.com.playall.partner" ],
            "paths": [
             "/api/admin/*",    // App 1 handles /api/admin/*
              
           ]
          }
       ]
   }
}
  // useEffect(() => {
  //   getAssetAppleLink().then((response) => {
  //     console.log('AppleAsset links:', response.data);
  //       setAssetLinks(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching apple assetlinks.json:', error);
  //     });
  // }, []);

  return (
    <div>
      {/* Render the asset links JSON here */}
      {assetLinks && (
        <pre>{JSON.stringify(assetLinks, null, 2)}</pre>
      )}
    </div>
  );
}

export default AssetApplelink;