import React, { useContext, useState } from 'react';
 
import {
    Button,
    Form,
    
} from "semantic-ui-react";

import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { addPayments } from "../../../apiclients/AcademyClient";
import Payment from "../../dashboard/Payment";

const AddPayment = (props) => {
    const { showModal, setReload, studentData,setMessage, isLoading, selectedSport } = props;

    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const arenaData = context.arenaData[arenaId];
    const sportId = selectedSport.key;
    const studentId = studentData.studentId;
    const enrollmentId = studentData.enrollmentId;


    const [remarks, setRemarks] = useState();
    const [paymentArray, setPaymentArray] = useState([]);
    const [totalPayment, setTotalPayment] = useState();


    const isSubmitAllowed =  totalPayment != null && totalPayment <= studentData.amountDue;
    

    const updatePaymentArray = (updatedArray) => {
        const amountPaid = updatedArray.reduce((a, b) => a + Number(b.amount), 0);
        setTotalPayment(amountPaid);
        setPaymentArray(updatedArray);
         
    };

    const modalContent = (
        <div>
            <Form>  
                <>
                    <div style={{ paddingBottom: "10px" }}>
                        <span style={{ fontWeight: "bold" }}>
                            Amount Due : Rs.
                        </span>
                        <span> {studentData.amountDue}/-</span>
                    </div>
                    <div style={{ marginBottom: "7px", fontWeight: "bold" }}>
                        Payment Breakup
                    </div>
                    <Payment
                        paymentArray={paymentArray}
                        setPaymentArray={updatePaymentArray}
                    />
                    <div style={{ paddingTop: "10px" }}>
                        <span style={{ fontWeight: "bold" }}>Total Payment: Rs. </span>
                        <span>{totalPayment}/-</span>
                    </div>
                    <Form.Group widths='equal'>
                            <Form.Field  >
                                <label>Remarks</label>
                                <input
                                    style={{ marginRight: "6px" }}
                                    type='text'
                                    value={remarks}
                                    placeholder='Additional information'
                                    onChange={(evt) => setRemarks(evt.target.value)}
                                />
                            </Form.Field>
                        </Form.Group>
                </>
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button primary disabled={!isSubmitAllowed} onClick={addPayment}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addPayment = () => {
        showModal(false);
        isLoading(true);
        const postBody = {
            facilityId, arenaId, sportId, studentId, enrollmentId, paymentInfo: paymentArray, amountDue : studentData.amountDue, remarks,
            createdBy: {
                userName: context.user.userName,
                userId: context.user.userId,
            },arenaData
        };

        addPayments(
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Payment Added",
                    message: "Payment has been added successfully.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });


    }
    return (
        <>
            <ModalPortal
                title='Add Payment'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}
export default AddPayment;