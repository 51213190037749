import React from "react";
import { Button, Table} from "semantic-ui-react";
import CustomModal from "../generic/CustomModal";
import {
   
  getSourceLabel,
} from "../../utils/helperFunctions";
import { colors } from "@material-ui/core";
import "../../App.css";
// import { NavLink } from "react-router-dom";
import { ROLES } from "../../utils/constants";
// import moment from "moment";
import { deleteTransaction } from "../../apiclients/ReportsApiClient";

const Footer = ({data, setClose, role,setMessage, isLoading,setMessageContent}) => {
  const deleteTransactions = (data) => {
    setClose(true);
    isLoading(true);
    deleteTransaction(data
    ).then((data) => {
      setMessage(true);
      setMessageContent({
        header: "Transaction Deleted",
        message: "Transaction has been deleted  successfully.",
        color: "green",
    });
    }).catch((err)=>{
      setMessage(true);
      setMessageContent({
        header: "Error!",
        message: err,
        color: "red",
    });
    });
  }

  const isDeleteAllowed = false;
  // Math.floor(moment.duration((moment()).diff(moment(data.createdDate))).asHours()) < 24 &&  (role === ROLES.ADMIN || role === ROLES.ACCOUNTANT);
  console.log("isDeleteAllowed ",isDeleteAllowed);
  return (<>
 {isDeleteAllowed ?<Button className={"ui button booked-footer"} onClick={()=>deleteTransactions(data)} >
      Delete
    </Button>: null}
    <Button className={"ui button "} onClick={setClose}>
      Close
    </Button>
    </>
  );
};
const Detail = ({ data, role }) => {
  const parray = data.payment ? data.payment : data.paymentMode;
  return (
    <div>
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <div>ID</div>
            </Table.Cell>
            <Table.Cell>
              <div>
                {data.id}
                <span
                  style={{
                    color: colors.red["500"],
                    textTransform: "uppercase",
                  }}
                >
                  {data.bookingStatus && data.bookingStatus !== "booked"
                    ? "  ( " + data.bookingStatus + " )"
                    : ""}
                </span>
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div>Arena</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.arenaName}</div>
            </Table.Cell>
          </Table.Row>
          {data.userName ? <Table.Row>
            <Table.Cell>
              <div>Customer Name</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.userName}</div>
            </Table.Cell>
          </Table.Row> : null}
          {data.userMobileNumber && role !== ROLES.PARTNER  ? <Table.Row>
            <Table.Cell>
              <div>Customer Number</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.userMobileNumber}</div>
            </Table.Cell>
          </Table.Row> : null}
          {data.email ? (
            <Table.Row>
              <Table.Cell>
                <div>Email</div>
              </Table.Cell>
              <Table.Cell>
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ paddingRight: "7px" }}>
                      <span>{data.email}</span>
                    </div>
                  </div>
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          <Table.Row>
            <Table.Cell>
              <div>Transaction Type</div>
            </Table.Cell>
            <Table.Cell>
              <div style={{ textTransform: "capitalize" }}>
                {data.type} {data.subtype ? ", " + data.subtype : ""}
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div>Created Date & Time</div>
            </Table.Cell>
            <Table.Cell>
              <div>{new Date(data.createdDate).toLocaleDateString()} | {data.createdTime}</div>
            </Table.Cell>
          </Table.Row>
          {data.bookingDate ? (<Table.Row>
            <Table.Cell>
              <div>Booking Date  </div>
            </Table.Cell>
            <Table.Cell>
              <div>{new Date(data.bookingDate).toLocaleDateString()} </div>
            </Table.Cell>
          </Table.Row>) : null}
          {data.sport?.sportName ? <Table.Row>
            <Table.Cell>
              <div>Booked For</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.alternativeSport ? data.alternativeSport.text : data.sport?.sportName}</div>
            </Table.Cell>
          </Table.Row> : null}
          {data.court ? (
            <Table.Row>
              <Table.Cell>
                <div>Slot Booked</div>
              </Table.Cell>
              <Table.Cell>
                <div>{data.slot
                  ? data.slot.interval?.start +
                  " - " +
                  data.slot.interval?.end
                  : ""},{" "}
                  {data.court.courtName}

                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          <Table.Row>
            <Table.Cell>
              <div>Booking Source</div>
            </Table.Cell>
            <Table.Cell>
              <div>{getSourceLabel(data.source)}</div>
            </Table.Cell>
          </Table.Row>
          {data.bulkBookingID ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Bulk Booking Details</div>
              </Table.Cell>
              <Table.Cell>
                <div> 
                    {data.startDate ? "Dates - " +data.startDate+" to "+data.endDate : data.bulkBookingID}
                    {data.slotDuration && " | Duration - " +data.slotDuration}
                    {data.courts && " | Courts - " +data.courts.map(x=>x.courtName)}
                    {data.courts && " | Courts - " +data.courts.map(x=>x.courtName)}
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
              {data.platform ? (
            <Table.Row>
              <Table.Cell>
                <div>Platform</div>
              </Table.Cell>
              <Table.Cell>
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ paddingRight: "7px" }}>
                      <span>{data.platform}</span>
                    </div>
                  </div>
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {data.cancelled || data.referenceID ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Reference ID</div>
              </Table.Cell>
              <Table.Cell>
                <div>{data.referenceID ? data.referenceID : data.cancellationTransactionId}</div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {data.rescheduledFrom ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Reference ID</div>
              </Table.Cell>
              <Table.Cell>
                <div>{data.rescheduledFrom.id}</div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {data.rescheduleTo ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Reference ID</div>
              </Table.Cell>
              <Table.Cell>
                <div>{data.rescheduleTo.id}</div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {data.extendFrom ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Reference ID</div>
              </Table.Cell>
              <Table.Cell>
                <div>{data.extendFrom.id}</div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          <Table.Row>
            <Table.Cell>
              <div>Total Amount</div>
            </Table.Cell>
            <Table.Cell>
              <div>Rs. {data.totalAmount}/-</div>
            </Table.Cell>
          </Table.Row>
      
          {data.discountAmount ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Discount Amount</div>
              </Table.Cell>
              <Table.Cell>
                  Rs. {data.discountAmount}/-
              </Table.Cell>
            </Table.Row>
          ) : null}
            {data.netAmount ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Net Amount</div>
              </Table.Cell>
              <Table.Cell>
                  Rs. {data.netAmount}/-
              </Table.Cell>
            </Table.Row>
          ) : null}
           {data.creditsUsed ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Credit Used</div>
              </Table.Cell>
              <Table.Cell>
                  Rs. {data.creditsUsed}/- | Wallet ID: {data.walletId}
              </Table.Cell>
            </Table.Row>
          ) : null}
              <Table.Row>
            <Table.Cell>
              <div>Amount Paid</div>
            </Table.Cell>
            <Table.Cell>
              <div>Rs. {data.amountPaid}/-</div>
            </Table.Cell>
          </Table.Row>
          {data.amountRefund ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Amount Refunded</div>
              </Table.Cell>
              <Table.Cell>
                <div style={{ fontWeight: "bold", color: colors.red["500"] }}>
                  Rs. {data.amountRefund}/-
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {data.paymentStatus && (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Payment Status</div>
              </Table.Cell>
              <Table.Cell>
                <div >
                  {data.paymentStatus}
                </div>
              </Table.Cell>
            </Table.Row>
          )}
          {data.amountDue ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Amount Due</div>
              </Table.Cell>
              <Table.Cell>
                <div style={{ fontWeight: "bold", color: colors.red["500"] }}>
                  Rs. {data.amountDue}/-
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {parray && parray.length > 0? (
            <Table.Row>
              <Table.Cell>
                <div>Payment Breakup</div>
              </Table.Cell>
              <Table.Cell>
                <div>
                  {data.products ? "Product rented/purchased along" : ""}
                  {parray.map((x) => {
                    return (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "7px" }}>
                          <span style={{ fontWeight: "600" }}>Amount: </span>
                          <span>Rs. {x.amount}/-</span>
                        </div>
                        <div style={{ paddingRight: "7px" }}>
                          <span style={{ fontWeight: "600" }}>Mode: </span>
                          <span style={{ fontTransform: "capitalize" }}>
                            {x.mode}
                          </span>
                        </div>
                        {x.remark ? (
                          <div style={{ paddingRight: "7px" }}>
                            <span style={{ fontWeight: "600" }}>Remark: </span>
                            <span>{x.remark}</span>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}

          {data.rentalProducts && data.rentalProducts.length > 0 ? (
            <Table.Row>
              <Table.Cell>
                <div>Rental Details</div>
              </Table.Cell>
              <Table.Cell>
                <div>
                  {data.rentalProducts.map((x) => {
                    return (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "7px" }}>
                          <span style={{ fontWeight: "600" }}>
                            ProductName:{" "}
                          </span>
                          <span>{x.name}</span>
                        </div>
                        <div style={{ paddingRight: "7px" }}>
                          <span style={{ fontWeight: "600" }}>Quantity: </span>
                          <span style={{ fontTransform: "capitalize" }}>
                            {x.quantity}
                          </span>
                        </div>
                        <div style={{ paddingRight: "7px" }}>
                          <span style={{ fontWeight: "600" }}>Discount: </span>
                          <span>{x.discount}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {data.sellProducts && data.sellProducts.length > 0 ? (
            <Table.Row>
              <Table.Cell>
                <div>Sell Details</div>
              </Table.Cell>
              <Table.Cell>
                <div>
                  {data.sellProducts.map((x) => {
                    return (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "7px" }}>
                          <span style={{ fontWeight: "600" }}>
                            ProductName:{" "}
                          </span>
                          <span>{x.name}</span>
                        </div>
                        <div style={{ paddingRight: "7px" }}>
                          <span style={{ fontWeight: "600" }}>Quantity: </span>
                          <span style={{ fontTransform: "capitalize" }}>
                            {x.quantity}
                          </span>
                        </div>
                        <div style={{ paddingRight: "7px" }}>
                          <span style={{ fontWeight: "600" }}>Discount: </span>
                          <span>{x.discount || 0}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {data.productDetails ? (
            <Table.Row>
              <Table.Cell>
                <div>Product Details</div>
              </Table.Cell>
              <Table.Cell>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ paddingRight: "7px" }}>
                          <span style={{ fontWeight: "600" }}>
                            ProductName:{" "}
                          </span>
                          <span>{data.productDetails.productName}</span>
                        </div>
                        <div style={{ paddingRight: "7px" }}>
                          <span style={{ fontWeight: "600" }}>Quantity: </span>
                          <span style={{ fontTransform: "capitalize" }}>
                            {data.productDetails.quantity}
                          </span>
                        </div>
                        <div style={{ paddingRight: "7px" }}>
                          <span style={{ fontWeight: "600" }}>Remarks: </span>
                          <span>{data.productDetails.remarks}</span>
                        </div>
                      </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {data.partnerBookingId ? (
            <Table.Row>
              <Table.Cell>
                <div>Partner Booking ID</div>
              </Table.Cell>
              <Table.Cell>
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ paddingRight: "7px" }}>
                      <span>{data.partnerBookingId}</span>
                    </div>
                  </div>
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {data.remarks ? (
            <Table.Row>
              <Table.Cell>
                <div>Remarks</div>
              </Table.Cell>
              <Table.Cell>
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ paddingRight: "7px" }}>
                      <span>{data.remarks}</span>
                    </div>
                  </div>
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
        </Table.Body>
      </Table>
    </div>
  );
};
const TransactionDetails = ({ setShowDetails, selectedData, role ,setMessage,isLoading,setMessageContent}) => {
  const setClose = () => {
    setShowDetails(false);
  };
  return (
    <CustomModal
      header={"Transaction Detail"}
      content={<Detail data={selectedData} role={role} />}
      footer={<Footer setClose={setClose} role={role} data={selectedData} setMessage={setMessage} setMessageContent={setMessageContent} isLoading={isLoading}/>}
      setOpen={setClose}
      open={!!selectedData}
    />
  );
};

export default TransactionDetails;
