import React, { useContext, useState, useEffect } from "react";
import GenericLoader from "../generic/GenericLoader";
import { Tab, Icon, Table, Button } from "semantic-ui-react";
import { COUPON_TYPE, ROLES } from "../../utils/constants";
import { AppContext } from "../../AppContext";
import Toast from "../generic/Toast";
// import { colors } from "@material-ui/core";
import AddBanner from "./AddBanner";
import { getAllBanners, disablebBanner } from "../../apiclients/WalletApiClient";
// import { convertFirstoreDate } from "../../utils/helperFunctions";
// import { set } from "react-ga";
import moment from 'moment';
function firestoreTimestampToFormattedDate(timestamp) {
    const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    return moment(milliseconds).format('DD MMM YYYY');
}

const CouponTable = ({
    bannersData,
    disableBannerFunction,
    setEditdata,
    couponInActive,
    // setCouponUsage
}) => {
    return (
        <>
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>S.No</Table.HeaderCell>
                        <Table.HeaderCell>Image Url</Table.HeaderCell>
                        <Table.HeaderCell>Redirection Url</Table.HeaderCell>
                        <Table.HeaderCell>Ranking</Table.HeaderCell>
                        <Table.HeaderCell>Lat</Table.HeaderCell>
                        <Table.HeaderCell>Lon</Table.HeaderCell>
                      
                        <Table.HeaderCell>Start Date</Table.HeaderCell>
                        <Table.HeaderCell>End Date</Table.HeaderCell>
                        <Table.HeaderCell>Remark</Table.HeaderCell>
                        <Table.HeaderCell>Created Date</Table.HeaderCell>
                        <Table.HeaderCell>Created By</Table.HeaderCell>
                       
                        <Table.HeaderCell>Action</Table.HeaderCell>

                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {bannersData && bannersData.map((x, i) => {
                        console.log("x", x)
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>{i + 1}</Table.Cell>
                                <Table.Cell>{x.imageURL}</Table.Cell>
                                <Table.Cell>{x.redirectionURL}</Table.Cell>
                                <Table.Cell>{ x.ranking}</Table.Cell>
                                <Table.Cell>{x.location.lat }</Table.Cell>
                                <Table.Cell>{x.location.lon }</Table.Cell>
                                
                                <Table.Cell>{firestoreTimestampToFormattedDate(x.startDate)}</Table.Cell>
                                <Table.Cell>{firestoreTimestampToFormattedDate(x.endDate)}</Table.Cell>
                                <Table.Cell>{x.remarks && x.remarks }</Table.Cell>
                                <Table.Cell>{firestoreTimestampToFormattedDate(x.createdDate)}</Table.Cell>
                                <Table.Cell>{x.createdBy.userName}</Table.Cell>
                               
                                <Table.Cell >
                                   
                                    {!couponInActive &&
                                        <div>
                                            <Button
                                                style={{
                                                    padding: "8px",
                                                    fontSize: "12px",
                                                    marginBottom: "8px",
                                                    // backgroundColor: colors.orange["800"],
                                                    // color: "#fff",
                                                }}
                                                onClick={() => { setEditdata({ show: true, data: x }) }}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                style={{
                                                    padding: "8px",
                                                    fontSize: "12px",
                                                    marginBottom: "8px",

                                                }}
                                                onClick={() => disableBannerFunction(x.id
                                                )}
                                            >
                                                Disable
                                            </Button>
                                        </div>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    );
};


const BannerManagement = (props) => {
    const { history } = props;
    const context = useContext(AppContext);
    const [activeIndex, setActiveIndex] = useState(0);
    const [reload, setReload] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [messageContent, setMessageContent] = useState({});
    const [addModal, setAddModal] = useState(false); // to show add modal
    const [editData, setEditdata] = useState({ show: false, data: {} }); // to show add modal
    // const [couponsData, setCouponData] = useState([]);
    const [bannersData, setBannersData] = useState([]);

    // const [couponUsage, setCouponUsage] = useState({ show: false, data: {} });
    const types = [COUPON_TYPE.active, COUPON_TYPE.inactive, COUPON_TYPE.disabled];

    const disableBannerFunction = (id) => {
        console.log("disableCoupon", id);
        setisLoading(true);

        disablebBanner(
            id
        ).then(() => {
            setReload(true);
            //   setisLoading(false);
        }).catch((error) => {
            console.log(error);
            setisLoading(false);
        });
    };

    const panes = [
        {
            menuItem: "Active Banners",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <CouponTable
                        bannersData={bannersData}
                        disableBannerFunction={disableBannerFunction}
                        setEditdata={(data) => { setEditdata(data) }}
                        // setCouponUsage={(data) => { setCouponUsage(data) }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "InActive Banners",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <CouponTable
                        bannersData={bannersData}
                        disableBannerFunction={disableBannerFunction}
                        setEditdata={(data) => { setEditdata(data) }}
                        couponInActive={true}
                        // setCouponUsage={(data) => { setCouponUsage(data) }}
                    />
                </Tab.Pane>
            ),
        },

        {
            menuItem: "Disable Banners",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <CouponTable
                          bannersData={bannersData}
                          disableBannerFunction={disableBannerFunction}
                        setEditdata={(data) => { setEditdata(data) }}
                        couponInActive={true}
                        // setCouponUsage={(data) => { setCouponUsage(data) }}
                    />
                </Tab.Pane>
            ),
        },

    ];


    useEffect(() => {
        setisLoading(true);
        getAllBanners(types[activeIndex]).then(
            (response) => {

                setisLoading(false);
                setReload(false);
                if (response.status === 200) {
                    const data = response.data;
                    setBannersData(data);
                }
            }
        ).catch((err) => {
            history.push("/extranet/dashboard");
        });
    }, [activeIndex, reload]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{ padding: "16px" }}>
            <Toast
                showMessage={showMessage}
                setShowMessage={setShowMessage}
                message={messageContent.message}
                messageHeader={messageContent.header}
                color={messageContent.color}
            />
            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>Banner Management</span>
                    </div>
                    <div style={{ fontSize: "14px" }}>

                        <Button
                            style={{ marginLeft: "20px" }}
                            onClick={() => setAddModal(true)}
                            name={"Add Package"}
                            disabled={context.user.role === ROLES.ADMIN || context.user.role === ROLES.MARKETING_MANAGER ? false : true}
                        >
                            <Icon name={"add"} /> Create a Banner
                        </Button>

                    </div>
                </div>




            </div>
            <br />
            {isLoading ? (
                <GenericLoader />
            ) : (
                <Tab
                    activeIndex={activeIndex}
                    onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
                    renderActiveOnly={true}
                    menu={{
                        color: "orange",
                        pointing: true,
                    }}
                    panes={panes}
                />
            )}
            {addModal ? (
                <AddBanner
                    showModal={setAddModal}
                    setReload={setReload}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                />
            ) : null}

            {editData?.show ? (
                <AddBanner
                    showModal={(value) => setEditdata({ show: false, data: {} })}
                    setReload={setReload}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                    data={editData?.data}
                    edit={editData?.show}
                />
            ) : null}
         

        </div>
    );
};

export default BannerManagement;