import React, { useContext, useState, useEffect } from 'react';
// import { colors } from "@material-ui/core";
import {
    Button,
    Dropdown,
    Form,
    // Checkbox,
} from "semantic-ui-react";
import ModalPortal from '../generic/ModalPortal';
import { AppContext } from '../../AppContext';
import { addExpenses } from '../../apiclients/AccountsClient';
import { EXPENSE_CATEGORY } from '../../utils/constants';
import {getAllSportsOfArena} from '../../apiclients/BookingApiClient';

const AddExpense = (props) => {
    const {  showModal, setReload, setMessage, isLoading } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;

    const [amount, setAmount] = useState();
    const [category, setCategory] = useState();
    const [remarks, setRemarks] = useState();
    const [selectedSport, setSelectedSport] = useState();
    const [listOfSports, setListOfSports] = useState([]);
 
  
    const isSubmitAllowed =  amount && category && remarks && selectedSport;

    useEffect(()=>{
        getAllSportsOfArena(facilityId, arenaId).then(
            (response) => {
              const sports = response.data;
              const dropdownSportsData = sports.map(
                (x, index) => ({
                  key: x.sportId,
                  value: x.sportId,
                  text: x.sportName,
                })
              );
               dropdownSportsData.unshift({
                key: 'generic',
                value: 'generic',
                text: 'General',
              });
              setListOfSports(dropdownSportsData);
              setSelectedSport(dropdownSportsData[0]);
            }
          );
    },[]);
    const changeCategory = (event, { value }) => {
        setCategory(value);
           };
    const changeSports = (event, { value }) => {
    setSelectedSport(value);
        };

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >

                        <label>Category</label>
                        <Dropdown
                            onChange={changeCategory}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={category}
                            options={EXPENSE_CATEGORY}
                            style={{ marginRight: "30px" }}
                        />
                         
                    </Form.Field>
                    <Form.Field  >
                    <label>Sports</label>
                        <Dropdown
                            onChange={changeSports}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={selectedSport && selectedSport.value}
                            options={listOfSports}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Amount</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={amount}
                            placeholder='5000'
                            onChange={(evt) => setAmount(evt.target.value)}

                        />
                    </Form.Field>
                    
                   
                </Form.Group>
                <Form.Group widths='equal'>
                <Form.Field  >
                        <label>Remarks</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={remarks}
                            placeholder='Give detail of Expenses'
                            onChange={(evt) => setRemarks(evt.target.value)}

                        />
                    </Form.Field>
</Form.Group>

            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
            
                <Button disabled={!isSubmitAllowed} primary onClick={addExpense}>  Submit</Button>
                </React.Fragment>
        );
    };

    const addExpense = () => {
        const createdBy = {
            userName: context.user.userName,
            userId: context.user.userId,
        };
        showModal(false);
        isLoading(true);
        const postBody = {
            facilityId, arenaId, amount , category, selectedSport,remarks, createdBy
        };

        addExpenses(
            postBody
        ).then((response) => {

            if( response.status === 200 || response.status === 201){
            setMessage({
                header: "Expense Added",
                message: "Expense has been added successfully.",
                color: "green",
              });

            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                  });
            }
            setReload(true);
        }).catch((error) => {
    
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
              });
            // ..
          });
    }
    return (
        <>
            <ModalPortal
                title='Add Expense'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default AddExpense;