import React from 'react';
import {
    Button,
} from "semantic-ui-react";

import ModalPortal from '../generic/ModalPortal';
// import { AppContext } from '../../AppContext';
import { deleteCategoryFromDB } from '../../apiclients/ProductApiClient';
const DeleteCategory = (props) => {
    const {  showModal, setReload, categoryData,type, setMessage,isLoading } = props;
    // const context = useContext(AppContext);
    // const facilityId = context.selectedFacility;

    const modalContent = (
        <div>
            Are you sure you  want to delete the {categoryData.name} category?
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
            
                <Button  primary onClick={deleteACategory}>  Submit</Button>
                </React.Fragment>
        );
    };

    const deleteACategory = () => {

        showModal(false);
        isLoading(true);

        const postBody = { category : categoryData, type};
        deleteCategoryFromDB(
            postBody
        ).then((response) => {

            isLoading(false);
            if( response.status === 200 || response.status === 201){
                setMessage({
                    header: "Category Deleted",
                    message: "Category has been successfully deleted.",
                    color: "green",
                  });
            }  else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                  });
            }
            setReload(true);
        }).catch((error) => {
    
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
              });
            // ..
          });
    }
    return (
        <>
            <ModalPortal
                title='Delete Category'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default DeleteCategory;