import React, { useContext, useState } from 'react';
import { colors } from "@material-ui/core";
import {
    Button,
    Dropdown,
    Form,
    Checkbox,
} from "semantic-ui-react";

import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { editBatch } from "../../../apiclients/AcademyClient";
 

const EditBatch = (props) => {
    const {  showModal, setReload, setMessage, isLoading, selectedSport, batchData } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const sportId = selectedSport.key;
    const skills = [
        {key : 0, value : 'Beginner',text : 'Beginner' },
        {key : 1, value : 'Intermediate',text : 'Intermediate' },
        {key : 2, value : 'Advance',text : 'Advance' },
        {key : 3, value : 'Expert',text : 'Expert' },
    ];
   
    const [batchName, setBatchName] = useState(batchData.batchName);
    const [skill, setSkill] = useState(batchData.skill);
    const [startTime, setStartTime] = useState(batchData.startTime);
    const [endTime, setEndTime] = useState(batchData.endTime);
    const [fees, setFees] = useState(batchData.fees);
    const [registrationFees, setRegistrationFees] = useState(batchData.registrationFees);
    const [days, setDays] = useState(batchData.days);
    const [remarks, setRemarks] = useState(batchData.remarks);
    const [numberOfDays, setNumberOfDays] = useState(batchData.numberOfDays);

    const daysOfWeek = [
        {key : 0, value : 'Monday',text : 'Monday' },
        {key : 1, value : 'Tuesday',text : 'Tuesday' },
        {key : 2, value : 'Wednesday',text : 'Wednesday' },
        {key : 3, value : 'Thursday',text : 'Thursday' },
        {key : 4, value : 'Friday',text : 'Friday' },
        {key : 5, value : 'Saturday',text : 'Saturday' },
        {key : 6, value : 'Sunday',text : 'Sunday' },
    ];
  
    const isSubmitAllowed =  batchName && skill && startTime && endTime && fees && days.length > 0;
  

    const updateAllDays = (checked) => {
        if (checked) {
            setDays(daysOfWeek);
        } else {
            setDays([]);
        }
    };

    const updateDays = (selectedDay) => {
        if (days.some(o => o.key === selectedDay.key)) {
            setDays(days.filter(o => o.key !== selectedDay.key));
        } else {
            setDays([...days, selectedDay]);
        }

    };

    const changeSkill = (event, { value }) => {
        setSkill(value);
           };

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Batch Name</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={batchName}
                            placeholder='Beginner - MWF - 4-5 PM'
                            onChange={(evt) => setBatchName(evt.target.value)}

                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Skills</label>
                        <Dropdown
                            onChange={changeSkill}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={skill}
                            options={skills}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Monthly Fees</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={fees}
                            placeholder='5000'
                            onChange={(evt) => setFees(evt.target.value)}

                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Registration Fees</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={registrationFees}
                            placeholder='1000'
                            onChange={(evt) => setRegistrationFees(evt.target.value)}

                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Start Time</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={startTime}
                            placeholder='16:00'
                            onChange={(evt) => setStartTime(evt.target.value)}

                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>End Time</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={endTime}
                            placeholder='17:00'
                            onChange={(evt) => setEndTime(evt.target.value)}

                        />
                    </Form.Field>
                   
                </Form.Group>
                <Form.Group widths='equal'>

                    <Form.Field  >

                        <label>
                            Days
                            <Checkbox
                                checked={
                                    daysOfWeek.length === days.length
                                }
                                style={{ marginRight: "7px", marginLeft: "7px" }}
                                onClick={(e, { checked }) => {
                                    updateAllDays(checked);
                                }}
                            />


                            <span style={{ fontWeight: "lighter" }}> All Days </span>
                        </label>
                        {daysOfWeek.map((x) => {
                            const isActive = days.some(
                                (mode) => mode.key === x.key
                            );
                            return (
                                <Button
                                    key={x.key}
                                    onClick={() => { updateDays(x) }}
                                    style={{
                                        marginRight: "10px",
                                        backgroundColor: isActive
                                            ? colors.orange[700]
                                            : colors.grey[200],
                                        color: isActive ? colors.blue[50] : colors.black,
                                    }}
                                >
                                    {x.text}
                                </Button>
                            );
                        })}

                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                <Form.Field  >
                        <label>Number of Days (Monthly)</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={numberOfDays}
                            placeholder='22'
                            onChange={(evt) => setNumberOfDays(evt.target.value)}

                        />
                    </Form.Field>
                <Form.Field  >
                        <label>Remarks</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={remarks}
                            placeholder='E.G. Shuttles included'
                            onChange={(evt) => setRemarks(evt.target.value)}

                        />
                    </Form.Field>
</Form.Group>

            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
            
                <Button disabled={!isSubmitAllowed} 
                    style={{
                        backgroundColor: colors.orange["800"],
                        color: "#fff",
                    }}
                onClick={editBatchData}>  Edit</Button>
                </React.Fragment>
        );
    };

    const editBatchData = () => {

        showModal(false);
        isLoading(true);
        const postBody = {
            facilityId, arenaId, sportId, batchName, skill, days, startTime, endTime, fees, remarks, numberOfDays, createdAt : batchData.createdAt, registrationFees
        };

        editBatch(
            postBody
        ).then((response) => {
            if( response.status === 200 || response.status === 201){
            setMessage({
                header: "Batch Added",
                message: "Batch has been added successfully.",
                color: "green",
              });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                  });
            }
            setReload(true);
        }).catch((error) => {
    
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
              });
            // ..
          });
    }
    return (
        <>
            <ModalPortal
                title='Edit Batch'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default EditBatch;