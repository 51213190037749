import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import GenericLoader from '../generic/GenericLoader';
import Toast from '../generic/Toast';
import { Button, Icon, Table } from 'semantic-ui-react';
import DatesSelection from '../common/DatesSelection';
import AddExpense from './AddExpense';
import moment from 'moment';
import { colors } from '@material-ui/core';
import { getAllExpenses } from '../../apiclients/AccountsClient';
import DeleteExpense from './DeleteExpense';
const ExpenseManagement = (props) => {
    const today = moment();
    const { history } = props;
    const context = useContext(AppContext);
    const { selectedFacility, selectedArena } = context;
    const [reload, setReload] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageContent, setMessageContent] = useState({});
    const [isLoading, setisLoading] = useState(false);
    const [addExpenseModal, setAddExpenseModal] = useState(false);
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [listOfExpenses, setListOfExpenses] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState();
    const loadData = () => {
        setisLoading(true);
        return getAllExpenses(
            selectedFacility,
            selectedArena.arenaId,
            startDate, endDate
        ).then((response) => {
            const data = response.data.data;
            setListOfExpenses(data);
            setisLoading(false);
        }).catch((err)=>{
            history.push("/extranet/dashboard");
        });
    };

    useEffect(() => {
        if (reload) {
            loadData().then(() => {
                setReload(false);
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 2000);
            });
        }
    }, [reload]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (selectedFacility && selectedArena) {
          loadData();
        }
    }, [selectedFacility,selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps

    const openDeleteModal = (transaction) => {
        setSelectedTransaction(transaction);
        setDeleteModal(true);
    };
    const changeDate = (isStart) => value => {
        if (isStart) {
            setStartDate(value);
        } else {
            setEndDate(value);
        }
    }
    return isLoading ? (
        <GenericLoader />
    ) : (
        <div style={{ padding: "16px" }}>

            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>Expenses</span>
                    </div>
                   
                <div style={{ fontSize: "14px" }}>
                        
                        <Button
                            style={{ marginLeft: "20px" }}
                            onClick={() => setAddExpenseModal(true)}
                            name={"Add Expense"}
                        >
                            <Icon name={"add"} />
                            Add an Expense
                        </Button>

                    </div>
                </div>
            </div>
            <div className='selectionFilters'>
                    <div>
                    <DatesSelection startDate={startDate} endDate={endDate} changeDate={changeDate}
                        disablePast={false}
                        disableFuture={true} />
                </div>
                <div>
                    <Button
                        onClick={() => loadData()}
                        style={{
                            marginRight: "10px",
                            backgroundColor: colors.orange[700],
                            color: colors.blue[50],
                        }}
                    >
                        Get Data
                    </Button>
                </div>
                </div>
                <hr/>
            <div style={{ paddingTop: "16px" }}>
                <Toast
                    showMessage={showMessage}
                    setShowMessage={setShowMessage}
                    message={messageContent.message}
                    messageHeader={messageContent.header}
                    color={messageContent.color}
                />
                <Table celled padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>S.No</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Time</Table.HeaderCell>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.HeaderCell>Category</Table.HeaderCell>
                            <Table.HeaderCell>Remarks</Table.HeaderCell>
                            <Table.HeaderCell>Created By</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { listOfExpenses.map((x, index) => {
                            return (
                                <Table.Row key={index} >
                                    <Table.Cell>{index + 1}</Table.Cell>
                                    <Table.Cell>{new Date(x.createdDate).toLocaleDateString()}</Table.Cell>
                                    <Table.Cell>{x.createdTime}</Table.Cell>
                                     <Table.Cell>Rs. {x.amountPaid} /-</Table.Cell>
                                     <Table.Cell>{x.subtype} </Table.Cell>
                                    <Table.Cell>{x.remarks} </Table.Cell>
                                    <Table.Cell>{x.createdBy.userName} </Table.Cell>
                                    
                                    <Table.Cell
                                        style={{
                                            flex: 1,
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            display: "flex",
                                        }}
                                    >
                                    { Math.floor(moment.duration((moment()).diff(moment(x.createdDate))).asHours()) > 24 ? 
                                    <Button
                                    style={{
                                        padding: "8px",
                                        fontSize: "12px",
                                        marginBottom: "8px",
                                    }}
                                    disabled
                                    // onClick={() => openDeleteModal(x)}
                                >
                                    Delete
                                </Button>
                                     : 
                                      <Button
                                      style={{
                                          padding: "8px",
                                          fontSize: "12px",
                                          marginBottom: "8px",
                                      }}
                                      onClick={() => openDeleteModal(x)}
                                  >
                                      Delete
                                  </Button>
                                    }

                                      
                                    </Table.Cell>
                               
                                </Table.Row>

                            );
                        })}
                    </Table.Body>
               
                </Table>
            
            </div>

            <br />
            How to Add, View or Delete Expense ?
            <div className="player">
                <iframe width="300" height="150" src="https://www.youtube.com/embed/gcFyiYqFA04" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen;"></iframe>
            </div>
            {addExpenseModal ? (
                <AddExpense
                    history={history}
                    showModal={setAddExpenseModal}
                    setReload={setReload}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                />
            ) : null}
            {deleteModal ? (
                <DeleteExpense
                    showModal={setDeleteModal}
                    setReload={setReload}
                    selectedTransaction={selectedTransaction} 
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                />
            ) : null}

        </div>
    );
};

export default ExpenseManagement;