import React, { useEffect, useState } from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooterPartner";
import "aos/dist/aos.css";
import { Fade, Zoom } from 'react-reveal';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CentralPopUp from './CentralPopUp'
import SuccessPopUp from './SuccessPopUp'
const bannerSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down'>BUILD. MONETIZE. MAINTAIN.</h4>
        <h1 data-aos='fade-up'>
          Upgrade your School Ground to a Top-Notch Sports Infrastructure with <span class='partnershipHeading1'>PROFESSIONAL </span>Maintenance & <span class='partnershipHeading1'>ZERO INVESTMENT</span>
        </h1>
      </div>
    </div>
    <div class='aboutarea '>
      <div class='aboutbox '>
        <div class='boxes '>
          <svg>
            <use href='#park'></use>
          </svg>
          <h5> 40+ Badminton Courts</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#football'></use>
          </svg>
          <h5> 30+ Turfs</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#support'></use>
          </svg>
          <h5> 9 Cities</h5>
        </div>

      </div>

    </div>
  </>
);



const Partnership = () => {
  const [openEnquiryForm, setEnquiryForm] = useState(false)
  const [successPopUp, setSuccessPopUp] = useState(false)
  const [popUpName, setPopUpName] = useState("")
  const [selectedQuestionIndex, setSelectedQuesionIndex] = useState(null)
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  console.log("width", width)

  const OnClickOnPlusButton = (index) => {
    console.log("index", index)
    console.log("selectedQuestionIndex", selectedQuestionIndex)
    if (selectedQuestionIndex === index) {
      setSelectedQuesionIndex(null)
      return
    }
    setSelectedQuesionIndex(index)
  }
  let questionAnswerArray = [
    {
      question: "What all does PlayAll needs from school?",
      answer: "We need the ground, drinking water access, washroom access, internet access, electrical connection (Electrcity charges to be paid by PlayAll on actuals), and  parking space (if not available outside school)"
    },
    {
      question: "What is the agreement duration?",
      answer: "On average our agreement duration is 12 years, but we are flexible depending upon the location and ground size"
    },
    {
      question: "How much area is needed?",
      answer: "Minimum requirement is 7000 sq ft (65 by 105 ft) but there is no maximum requirment, we can add sports according to the available land"
    },
    {
      question: "I already have good quality sports infra, can playAll help me maintan and montize it?",
      answer: "Yes, we have our own App with more than 60000 customer database and a dedicated team to ensure the infra is monetized optimally"
    },
    {
      question: "How much investment would PlayAll make?",
      answer: "It depends on the size of the land and the sport, it can range from 25 lakhs to 2 crore"
    },
    {
      question: "At what time will PlayAll Operate?",
      answer: "During working days, post school hours till 12 am and on holidays from 5 am to 12 am"
    },
    {
      question: "Who will manage the infra post school hours?",
      answer: "PlayAll team will manage the infra post school hours"
    },
    {
      question: "What all activities will PlayAll do at the school?",
      answer: "PlayAll will run the academy, pay & play, and community enagement activities"
    }
  ]
  const homeScreenData = (
    <>
      <main >
        <section
          id='corporateEvents'
          class='partnersec badmintondeco'
        >
          <div class='container'>
            <div class='innerbx'>
              <div class='imgarea rightbx'>
                <div class='lbp1 lboxst'>
                  <Fade left>
                    <img alt='' src='images/logo_modern.png' />
                  </Fade>
                </div>
                <div class='lbp4 lboxst' >
                  <Fade left>
                    <img alt='' src='images/logo_SVIS.png' />

                  </Fade>
                </div>
                <div class='lbp5 lboxst'>
                  <Fade left>
                    <img alt='' src='images/logo_DIS.png' />

                  </Fade>
                </div>
                <div class='lbp9 lboxst' >
                  <Fade left>
                    <img alt='' src='images/logo_St_Mary.png' />
                  </Fade>
                </div>
                <div class='lbp10 lboxst' >
                  <Fade left>
                    <img alt='' src='images/logo_fortune.png' />
                  </Fade>
                </div>
                <div class='lbp11 lboxst' >
                  <Fade left>
                    <img alt='' src='images/logo_NGF.png' />
                  </Fade>
                </div>
                <div class='lbp12 lboxst' >
                  <Fade left>
                    <img alt='' src='images/logo_KPS.png' />
                  </Fade>
                </div>
                <div class='lbp13 lboxst' >
                  <Fade left>
                    <img alt='' src='images/logo_BAGS.png' />
                  </Fade>
                </div>
                <div class='lbp14 lboxst' >
                  <Fade left>
                    <img alt='' src='images/logo_JIS.png' />
                  </Fade>
                </div>
              </div>
              <div class='contentarea '>
                <h4 >Partnering with India's Top Schools for</h4>
                <h2>Elite Sports Infrastructure</h2>
                <Zoom>
                  <span>
                    With partnerships that include renowned institutions like Sri Venkateshwar International School, Delhi International School, and Modern School, PlayAll brings world-class sports infrastructure to school campuses. Our facilities support a wide range of sports including Football, Basketball, Badminton, Cricket, Swimming, and Pickleball—providing students with top-tier facilities to nurture their athletic potential.
                    {/* <span class='playallst'>Play All</span> */}
                  </span></Zoom>
                <div class='btarea'>
                  <button
                    class='btorng'
                    onClick={() => { setEnquiryForm(true) }}
                  >Know More</button>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section id='techsection' class=' techsection cricketdeco'>
          <div class='container'>
            <div class='headarea'>
              <h4 class=' sectiontitle ' >
                How it works?
              </h4>
              <h2 class=' sectionhead fadeInDown wow'>

                PlayAll to Invest in Sports Infrastructure in School.{" "}
              </h2>
            </div>
            <div class='techarea'>

              <div class='techbox ' ><Zoom >
                <h2>During School Hours</h2>
              </Zoom>

                <span>
                  Our state-of-the-art sports infrastructure is <span class='playallst'>reserved exclusively for students</span> during school hours. This ensures that they benefit from top-quality facilities for physical education, practice, and inter-school sports, enriching their athletic development within a familiar environment.


                </span>
              </div>
              <div class='techbox ' >
                <Zoom ><h2>During Non-School Hours</h2></Zoom>
                
                <span>
                  Beyond school hours, PlayAll opens up the sports infrastructure to the broader community through<span class='playallst'> Pay & Play, sports academies, and other events.</span> This model not only maximizes facility use but also allows the community to access premium sports spaces for fitness, skill development, and recreational sports.
                </span>
              </div>
              <div class='techbox1'>

                <span>
                  This model enhances the sports experience not only for students but also for the nearby community. By investing in school sports infrastructure, PlayAll fosters an inclusive sports culture that benefits students, families, and local residents, creating a vibrant hub for physical activity and community engagement
                </span>
                <button class='btorng'
                  onClick={() => { setEnquiryForm(true) }}
                >
                  Learn More
                </button>
              </div>
            </div>

          </div>

        </section>

        <section id='consultancysec' class='consultancysec'>
          <div class='consultleft'></div>
          <div class='consultarea cosultancybg'>
            <div class='consultbox floattbox' >
              <h5 class=' sectiontitle '>But why?</h5>
              <Fade left cascade><h4>What’s the Benefit for Your School?</h4></Fade>
              <Fade bottom>
                <img alt='' class='imgstyle' src='images/consultancybox.png' /></Fade>
                <button 
                class='btorng'
                onClick={() => { setEnquiryForm(true) }}
                >Know More</button>
            </div>
            <div class='consultbox1'>
              <div class='innerbox'>
                <div class='circlest'>1</div>

                <Fade right cascade>
                  <b><h4>Zero Invesment</h4></b>
                  Schools can enjoy world-class sports infrastructure without any upfront cost—PlayAll takes care of the entire investment. 
                </Fade>
              </div>
              <div class='innerbox' >
                <div class='circlest'>2</div>

                <Fade right cascade>
                  <b><h4>Best-in-Class Sports Facilities </h4></b>
                  <span>
                  From FIFA-certified turfs to BWF-standard badminton courts, we provide facilities that meet international standards. </span>
                </Fade>
              </div>


              <div class='innerbox' >
                <div class='circlest'>5</div>

                <Fade right cascade>
                  <b> <h4>Professional Management</h4></b>
                  <span>
                  All facilities are managed by experienced professionals, ensuring safety, maintenance, and quality events for students.
                  </span></Fade>
              </div>
              <div class='innerbox' >
                <div class='circlest'>3</div>

                <Fade right cascade>
                  <b> <h4>Boost in Sports Admissions</h4></b>
                  <span>
                  Our premium facilities attract sports-focused students, enhancing the school’s reputation and appeal.</span></Fade>
              </div>

              <div class='innerbox' >
                <div class='circlest'>4</div>

                <Fade right cascade>
                  <b> <h4>Student Sponsorship Opportunities</h4></b>
                  <span>
                  Talented students may receive sponsorships for advanced training and competitions, supported by our corporate network.</span></Fade>
              </div>
            </div>
          </div>
        </section>

        <div>
          <div className="asFeaturesIn">
            <h4 class=' sectiontitle ' >
              As Featured in
            </h4>

          </div>
          <div >
            <div className="mainFeatureContainer">
              <div className="secondFeatureContainer">
                <div class='lbp6 lboxst'>
                  <Fade left>
                    <a href="https://economictimes.indiatimes.com/small-biz/sme-sector/diversifying-revenue-streams-how-entrepreneurs-and-medium-enterprises-profit-from-multi-functional-sports-spaces/articleshow/103079912.cms" target="_blank" rel="noopener noreferrer">


                      <img
                        alt=''
                        className='featureEcoLogo'

                        src='images/theEcoTimes.png'
                      />
                    </a>
                  </Fade>
                </div>
              </div>
              <div className="secondFeatureContainer">
                <div class='lbp6 lboxst'>
                  <Fade left>
                    <a href="https://sugermint.com/jayant-surana/" target="_blank" rel="noopener noreferrer">

                      <img
                        alt=''
                        className="featureLogo"
                        src='images/sugerMint.png'
                      />
                    </a>
                  </Fade>
                </div>


              </div>
              <div className="secondFeatureContainer">
                <div class='lbp6 lboxst'>
                  <Fade left>
                    <a href="https://indiaeducationdiary.in/playall-sports-unveils-three-state-of-the-art-arenas-in-prominent-locations-in-delhi-ncr/" target="_blank" rel="noopener noreferrer">

                      <img
                        alt=''
                        className="featureLogo"

                        src='images/indiaEdu.png'
                      />
                    </a>
                  </Fade>
                </div>


              </div>
              <div className="secondFeatureContainer">
                <div class='lbp6 lboxst'>
                  <Fade left>
                    <a href="https://trak.in/stories/exclusive-interview-this-sports-tech-startup-has-onboarded-43000-monthly-customers-100-corporates-for-unparalleled-sports-experience/" target="_blank" rel="noopener noreferrer">

                      <img
                        alt=''
                        className="featureLogo"
                        src='images/trakIn.png'
                      />
                    </a>
                  </Fade>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* <section class=' testimonialsec footballdeco'>
          <div class='container '>
            <div class='headarea'>
              <h4 class=' sectiontitle ' >
                TESTIMONIALS
              </h4>
              <h2 class=' sectionhead '>
                20+ Happy Partners
              </h2>
            </div>
            <div class='testimonialarea'>

              <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1600} swipeable={true} emulateTouch={true}>
                <div class='testimonbox '>
                  {" "}
                  <img
                    alt=''
                    class='testimonialimg'
                    src='images/shailendrsuyal.png'
                  />
                  <h4>Shailendr Suyal, Noida</h4>
                  <span>
                    Court is too good. One of the best courts if will be
                    maintained properly in near future. Play All is the best Badminton academy near me.
                  </span>
                </div>

                <div class='testimonbox '>
                  <img
                    alt=''
                    class='testimonialimg'
                    src='images/richakaushal.png'
                  />
                  <h4>Richa Kaushal, Gurugram</h4>
                  <span>
                    Very nice experience. Professional play. Must visit.
                    Please do come once. Marvellous.
                  </span>
                </div>

                <div class='testimonbox '>
                  <img alt='' class='testimonialimg' src='images/ashish.png' />
                  <h4>Ashish Srivastava, Faridabad</h4>
                  <span>
                    Easily accessible sports complex on tigaon road.
                    Coaches are available to guide development of skill
                    sets of all age groups.
                  </span>
                </div>

                <div class='testimonbox '>
                  <img alt='' class='testimonialimg' src='images/anubha.png' />
                  <h4>Anubha Sharma, Noida</h4>
                  <span>
                    My kids r doing training for last 3 month. Their front
                    office staff especially Deepak is very impressive in
                    explaining us the offers. Best wishes to Deepak and
                    his team for playall.{" "}
                  </span>
                </div>

                <div class='testimonbox '>
                  <img alt='' class='testimonialimg' src='images/ajay.jpg' />
                  <h4>Ajay Kumar, Noida</h4>
                  <span>
                    We have organized one corporate tournament which was
                    really very well with support of management. Best
                    place to play or organis any tournament.
                  </span>
                </div>

                <div class='testimonbox '>
                  <img
                    alt=''
                    class='testimonialimg'
                    src='images/jyotirawat.png'
                  />
                  <h4>Jyoti Rawat, Noida</h4>
                  <span>
                    "Great place to play,Friendly environment and great
                    facility..😊👍"{" "}
                  </span>
                </div>
                <div class='testimonbox '>
                  <img
                    alt=''
                    class='testimonialimg'
                    src='images/rahultyagi.png'
                  />
                  <h4>Rahul Tyagi, Noida</h4>
                  <span>
                    great place. around 8 Badminton courts. They have
                    training facility as well. They don't have AC, but
                    ventilation is good and very high roof. You can rent
                    out all equipment and buy some.Has the best Badminton courts near me.
                  </span>
                </div>

                <div class='testimonbox '>
                  <img alt='' class='testimonialimg' src='images/akshata.png' />
                  <h4>Akshata Aggarwal, Gurugram</h4>
                  <span>
                    I loved Play All. Amazing indoor badminton court near me,
                    well kept with all basic facilities available. Very
                    hygienic washrooms and changing room. Highly
                    recommended.
                  </span>
                </div>

                <div class='testimonbox '>
                  <img
                    alt=''
                    class='testimonialimg'
                    src='images/taranjeet.png'
                  />
                  <h4>Taranjeet Singh, Faridabad</h4>
                  <span>
                    Best experience ever to play here on badminton court &
                    futsal turf near me, they also have table tennis & box
                    cricket with well maintained & hygienic environment,
                    staff is well mannered too.{" "}
                  </span>
                </div>

              </Carousel>
            </div>

          </div>
        </section> */}

        <section id='gallery' class=' gallerysec'>
          <div class='headarea'>
            <h4 class=' sectiontitle ' >
              Gallery
            </h4>

            <Zoom top><h2 class=' sectionhead ' >
              Our Venues
            </h2>
            </Zoom>
          </div>

          <div class='galleryarea '>

            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >

              <Fade bottom >
                <img alt='' src='images/gallery/school_image_1.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              <Fade bottom >
                <img alt='' src='images/gallery/school_image_2.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              {" "}
              <Fade bottom >
                <img alt='' src='images/gallery/school_image_12.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              {" "}
              <Fade bottom >
                <img alt='' src='images/gallery/school_image_4.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              {" "}
              <Fade bottom >
                <img alt='' src='images/gallery/school_image_5.png' />
              </Fade>
            </div>
            <div class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'>
              <Fade bottom >
                <img alt='' src='images/gallery/school_image_10.png' />
              </Fade>
            </div>

            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              <Fade bottom >
                <img alt='' src='images/gallery/school_image_7.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              <Fade bottom >
                <img alt='' src='images/gallery/school_image_8.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              <Fade bottom >
                <img alt='' src='images/gallery/school_image_9.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              <Fade bottom >
                <img alt='' src='images/gallery/school_image_6.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              {" "}
              <Fade bottom >
                <img alt='' src='images/gallery/school_image_3.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              <Fade bottom >
                <img alt='' src='images/gallery/school_image_11.png' />
              </Fade>
            </div>

          </div>
        </section>
        <section id='faqpostion'>
          <div style={{}}>
            <div className="faqSection">
              <h4 class=' sectiontitle ' >
                FAQ
              </h4>

            </div>
            <div className="faqMainContiner">
              <div className="faqMainContinerInside">
                <div >
                  <Zoom>
                    <p className="frequentlyAskedpTag">
                      Frequently Asked Questions
                    </p>
                  </Zoom>
                </div>
                {width > 768 && (
                  <div className="">

                    <div className="getMorequestion">
                      <Fade left>
                        <span style={{ fontSize: "2vh" }}>
                          Get more questions? Feel free to contact us
                        </span>
                      </Fade>
                    </div>
                    <Fade left>
                      <div>

                        <button class='btwhite mr-4'
                          type="button"
                          style={{ color: "#ffffff", background: "#FF6D2C" }}
                          onClick={() => { setEnquiryForm(true) }}
                        > Contact Us</button>

                      </div>
                    </Fade>
                  </div>
                )}
              </div>
              <div className="faqMainContinerInside">
                {questionAnswerArray?.map((item, index) => {
                  return (
                    <div className="questionContainer">
                      <Fade right cascade>
                        <div className="faqsimplequestion"
                          onClick={() => { OnClickOnPlusButton(index) }}
                        >
                          <div style={{ flex: 10 }}>
                            <p className="questionPTag">{item?.question}</p>
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <button style={{background:"none",border:"none"}} > */}
                            <img
                              alt=''
                              className="plusImage"
                              src={selectedQuestionIndex === index ? 'images/minus.png' : 'images/plus.png'}

                            />
                            {/* </button> */}
                          </div>
                        </div>
                      </Fade>
                      {selectedQuestionIndex === index && (
                        <div className="answerDiv">
                          <p className="answerPTag">{item?.answer}</p>
                        </div>
                      )}

                    </div>
                  )
                })}

              </div>
              {width < 768 && (
                <div className="faqMainContinerInside">

                  <div className="getMorequestion">
                    <Zoom>
                      <span >
                        Get more questions? Feel free to contact us
                      </span>
                    </Zoom>
                  </div>
                  <Fade left>
                    <div className="getMorequestion">

                      <button class='btwhite mr-4'
                        type="button"
                        style={{ color: "#ffffff", background: "#FF6D2C" }}
                        onClick={() => { setEnquiryForm(true) }}
                      > Contact Us</button>

                    </div>
                  </Fade>
                </div>
              )}

            </div>
          </div>
        </section>
      </main>
    </>
  );
  return (
    <>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}

        <div class='headbanner partnershipbg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"home"} />
            {bannerSection}
          </div>
        </div>
        {homeScreenData}
        {footerSection}
        <CentralPopUp isOpen={openEnquiryForm} onClose={() => { setEnquiryForm(false) }} setSuccessPopUp={() => { setSuccessPopUp(true); setPopUpName("success") }} />
        <SuccessPopUp isOpen={successPopUp} onClose={() => { setSuccessPopUp(false) }} popName={popUpName} />
      </div>

    </>
  );
};

export default Partnership;
