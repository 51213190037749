import React, { useEffect, useContext, useRef, useState } from "react";
import {Table, Dropdown, Form } from "semantic-ui-react";
import { colors } from "@material-ui/core";
import { AppContext } from "../../../AppContext";
import { getAllAcademySportsOfArena, getAllStudentData, getStudentDetails } from "../../../apiclients/AcademyClient";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from "react-bootstrap-typeahead";
import StudentDetail from "./StudentDetails";


const StudentArchive = (props) => {

    const context = useContext(AppContext);
    const sports = useRef([]);

    const { history } = props;

    const [selectedSport, setSelectedSport] = useState({});
    // const [allAcademySports, setAllAcademySports] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState([]);
    const [selectedStudentData, setSelectedStudentData] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [showDetails, setShowDetails] = useState(false); // to show student detail modal
    // const [reload, setReload] = useState(false);
    // const [isLoading, setisLoading] = useState(true);
    // const [messageContent, setMessageContent] = useState({});
    // const types = ['active', 'inactive', 'old', 'deleted'];


    useEffect(() => {
        if (context.selectedFacility && context.selectedArena) {
            getAllAcademySportsOfArena(context.selectedFacility, context.selectedArena.arenaId).then(
                (response) => {
                    if (response.status === 200) {
                        const sport = response.data;
                        const dropdownSportsData = sport.map(
                            (x, index) => ({
                                key: x.sportId,
                                value: x.sportId,
                                text: x.sportName,
                            })
                        );
                        // setAllAcademySports(dropdownSportsData);
                        sports.current = dropdownSportsData;
                        setSelectedSport(dropdownSportsData[0]);
                        getAllStudentData(context.selectedFacility, context.selectedArena.arenaId, dropdownSportsData[0].value).then(
                            (result) => {
                                const data = result.data;
                                setStudentData(data);
                            }
                        )
                        // loadData(dropdownSportsData[0]);
                    } else {
                        history.push("/extranet/dashboard");
                    }

                }
            ).catch((err) => {
                history.push("/extranet/dashboard");
            });

        }
    }, [context.selectedFacility, context.selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps

    const changeSport = (event, { value }) => {
        const data = sports.current.find((facility) => facility.value === value);
        setSelectedSport(data);
        getAllStudentData(context.selectedFacility, context.selectedArena.arenaId, selectedSport.value).then(
            (result) => {
                const data = result.data;
                setStudentData(data);
            }
        );
    };

    const selectAStudent = (student) => {
        if(student.length > 0){
        setSelectedStudent(student);
        const stu = student[0];
        getStudentDetails(context.selectedFacility, context.selectedArena.arenaId, selectedSport.value, stu.studentId).then((response) => {
            const data = response.data;
            setSelectedStudentData(data);
        })
    } else {
        setSelectedStudent([]);
    }
    }


    const openStudentDetail = (data) => () => {
        setShowDetails(data);
    };

    return (
        <div style={{ padding: "16px" }}>
            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>Student Archives</span>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <div style={{ fontSize: "14px" }}>

                            <Dropdown
                                onChange={changeSport}
                                placeholder='Select...'
                                openOnFocus
                                selection
                                value={selectedSport && selectedSport.value}
                                options={sports.current}
                            />

                        </div>
                    </div>
                </div>


            </div>
            <br />
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Search by Number</label>
                        <Typeahead
                            id="basic-typeahead-multiple"
                            labelKey="mobile"
                            onChange={selectAStudent}
                            options={studentData}
                            placeholder="Type mobile number..."
                            selected={selectedStudent}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Search by Name</label>
                        <Typeahead
                            id="basic-typeahead-multiple"
                            labelKey="studentName"
                            onChange={selectAStudent}
                            options={studentData}
                            placeholder="Type student name..."
                            selected={selectedStudent}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Search by Parent's Name</label>
                        <Typeahead
                            id="basic-typeahead-multiple"
                            labelKey="parentName"
                            onChange={selectAStudent}
                            options={studentData}
                            placeholder="Type parent name..."
                            selected={selectedStudent}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
            <hr />
            {selectedStudentData.length > 0 ?
                <>
                    <Table celled padded>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>S.No</Table.HeaderCell>
                                <Table.HeaderCell>Student Name</Table.HeaderCell>
                                <Table.HeaderCell>Mobile</Table.HeaderCell>
                                <Table.HeaderCell>Parent's Name</Table.HeaderCell>
                                <Table.HeaderCell>Batch Name</Table.HeaderCell>
                                <Table.HeaderCell>End Date</Table.HeaderCell>
                                <Table.HeaderCell>Available Classes</Table.HeaderCell>
                                <Table.HeaderCell>Amount Due</Table.HeaderCell>
                                <Table.HeaderCell>Detail</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {selectedStudentData && selectedStudentData.map((x, i) => {
                                
                                return (
                                    <Table.Row key={i}>
                                        <Table.Cell>{i + 1}</Table.Cell>
                                        <Table.Cell>{x.studentName}</Table.Cell>
                                        <Table.Cell>{x.mobile}</Table.Cell>
                                        <Table.Cell>{x.parentName}</Table.Cell>
                                        <Table.Cell>{x.batchName}</Table.Cell>
                                        <Table.Cell>{new Date(x.endDate.seconds * 1000).toLocaleDateString()}</Table.Cell>
                                       
                                        <Table.Cell>{
                                            x.availableNoOfClasses < 0 ? <span style={{ color: 'red' }}>{x.availableNoOfClasses}</span> : x.availableNoOfClasses
                                        }</Table.Cell>
                                        <Table.Cell>
                                            {x.amountDue > 0 ?
                                                <div><span style={{ color: colors.red[500] }}> Rs. {parseFloat(x.amountDue).toFixed(2)}/-</span></div>
                                                :
                                                <div> Rs. {parseFloat(x.amountDue).toFixed(2)}/-</div>}

                                        </Table.Cell>

                                        <Table.Cell >
                                            <div
                                                style={{
                                                    textDecoration: "underline",
                                                    color: colors.blue[500],
                                                }}
                                                onClick={openStudentDetail(x)}
                                            >
                                                {x.enrollmentId}
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                    {!!showDetails ? (
                <StudentDetail
                    selectedData={showDetails}
                    setShowDetails={setShowDetails}
                    // setReload={setReload}
                    history={history}
                    // setMessage={setMessageContent}
                    // isLoading={isLoading}
                    selectedSport={selectedSport}
                    // type={type}
                />
            ) : null}
                </> : null
            }
        </div>
    );
};

export default StudentArchive;