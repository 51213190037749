import React, { useEffect, useContext, useState, useReducer } from "react";
import { Button, Icon, Modal, Table } from "semantic-ui-react";

import "../dashboard/style.scss";
import { AppContext } from "../../AppContext";
import { loadBookingTransactions } from "../../apiclients/ReportsApiClient";
import {
  convertFirstoreDate,
  convertFirstoreDateForExcel,
  getKeyValue,
  getSubtypeLabel,
  getBookingStatusLabel,
} from "../../utils/helperFunctions";
import CustomModal from "../generic/CustomModal";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { colors } from "@material-ui/core";
import {
  filterReducer,
  FILTERS_ACTION_TYPE,
  initialFilterState,
} from "./bookingReportReducer";
import GenericLoader from "../generic/GenericLoader";
import TransactionDetails from "./TransactionDetails";
import ExportToExcel from "../generic/ExportToExcel";
import {   PAYMENT_STATUS, ROLES } from "../../utils/constants";
import moment from "moment";
import Toast from "../generic/Toast";

const FilterFooter = ({ setShowFilter, onApply }) => {
  return (
    <Modal.Actions>
      <Button className={"ui button booked-footer"} onClick={onApply}>
        Apply
      </Button>
      <Button onClick={() => setShowFilter(false)}>Cancel</Button>
    </Modal.Actions>
  );
};

const FilterContent = ({ onApply, setShowFilter, selectedFilters, role }) => {
  const [filters, dispatch] = useReducer(filterReducer, {
    ...initialFilterState,
    ...selectedFilters,
  });
  const { startDate, endDate, paymentMode, bookingModes } = filters;

  const onReset = () => {
    dispatch({ type: FILTERS_ACTION_TYPE.RESET });
  };
  const onFilterApplyClicked = () => {
    onApply(filters);
  };
  const updateSelectedModes = (mode) => () => {
    dispatch({
      type: FILTERS_ACTION_TYPE.ADD_PAYMENT_MODES,
      payload: paymentMode.value === mode.value ? {} : mode,
    });
  };

  const updateSelectedBookingModes = (mode) => () => {
    const isAdded = bookingModes.some((x) => x.value === mode.value);
    if (isAdded) {
      dispatch({
        type: FILTERS_ACTION_TYPE.ADD_BOOKING_MODES,
        payload: bookingModes.filter((x) => x.value !== mode.value),
      });
    } else {
      dispatch({
        type: FILTERS_ACTION_TYPE.ADD_BOOKING_MODES,
        payload: [...bookingModes, mode],
      });
    }
  };

  return (
    <>
      <Modal.Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Filters</span>
          <span
            style={{
              textTransform: "uppercase",
              fontSize: "14px",
              color: colors.orange[500],
            }}
            onClick={onReset}
          >
            Clear all
          </span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Transaction Date
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div style={{ fontWeight: "bold" }}>Start Date</div>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              value={startDate}
              placeholder='Select Date...'
              onChange={(date) =>
                dispatch({
                  type: FILTERS_ACTION_TYPE.ADD_START_DATE,
                  payload: date,
                })
              }
              format='DD MMM yyyy'
              style={{ padding: "10px" }}

              minDate={!(role === ROLES.ADMIN || role === ROLES.PARTNER || role === ROLES.ACCOUNTANT || role === ROLES.AUDITOR) ? role === ROLES.REGIONAL_MANAGER ? moment().subtract(14, 'days'): moment().subtract(7, 'days') : undefined}
              maxDate={moment()}
            />
            <div style={{ fontWeight: "bold" }}>End Date</div>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              value={endDate}
              placeholder='Select Date...'
              onChange={(date) =>
                dispatch({
                  type: FILTERS_ACTION_TYPE.ADD_END_DATE,
                  payload: date,
                })
              }
              format='DD MMM yyyy'
              style={{ padding: "10px" }}


              minDate={!(role === ROLES.ADMIN || role === ROLES.PARTNER || role === ROLES.ACCOUNTANT ||  role === ROLES.AUDITOR) ? moment().subtract(7, 'days') : undefined}
              maxDate={moment()}
            />
          </div>
         
        </div>
      </Modal.Content>
      <FilterFooter
        onApply={onFilterApplyClicked}
        setShowFilter={setShowFilter}
      />
    </>
  );
};

const BookingFilters = ({
  setShowFilter,
  showFilter,
  selectedFilters,
  onApply,
  role
}) => {
  return (
    <CustomModal
      comp={true}
      content={
        <FilterContent
          onApply={onApply}
          selectedFilters={selectedFilters}
          setShowFilter={setShowFilter}
          role={role}
        />
      }
      setOpen={setShowFilter}
      open={showFilter}
    />
  );
};
const InvestorReport = () => {
  const { selectedFacility, selectedArena, user } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(initialFilterState);
  const [showFilter, setShowFilter] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({});
  const [grossReceipts, setGrossReceipt] = useState(0);
  const [totalNoOfTransaction, setTotalNoOfTransaction] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [netReceipts, setNetReceipt] = useState(0);
  const arenaIndex = user.facilityIncharge.findIndex(x=>x.arenaId === selectedArena.arenaId);
  
  useEffect(() => {
    if (!loadData) {
      setLoadData(true);
    }
  }, [selectedArena]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loadData) {
      const params = {
        startDate: selectedFilters.startDate,
        endDate: selectedFilters.endDate,
        type : "investor",
      };
      if (getKeyValue(selectedFilters, "paymentMode.value")) {
        params.paymentMode = selectedFilters.paymentMode.value;
      }
      if (
        selectedFilters.bookingModes &&
        selectedFilters.bookingModes.length > 0
      ) {
        params.bookingModes = selectedFilters.bookingModes.map((x) => x.value);
      }

      loadBookingTransactions(
        selectedFacility,
        selectedArena.arenaId,
        params
      ).then((res) => {
        let transactionData = res.data.data;
        if(user.facilityIncharge[arenaIndex].sportIds){ // that means the user access is restricted to a sport
          const listOfSports = user.facilityIncharge[arenaIndex].sportIds;
          transactionData  = transactionData.filter(x=>x.sport && listOfSports.includes(x.sport.sportId))
        }
        setData(transactionData);
        let summary = { grossReceipts: 0, commissions: 0, taxes: 0, numberOfTransactions: transactionData.length };
       
        for (var i = 0; i < transactionData.length; i++) {
          const transData = transactionData[i];
          summary.grossReceipts = summary.grossReceipts + transData.amountPaid;
          if(transData.hudlePassDiscount){
            let localCommission = (transData.totalAmount * transData.hudlePassDiscount) ? (transData.totalAmount * transData.hudlePassDiscount) : 0
            summary.commissions = summary.commissions + localCommission;
          }
          if(transData.thirdPartyCommission){
            let localCommission = (transData.totalAmount * transData.thirdPartyCommission) ? (transData.totalAmount * transData.thirdPartyCommission) : 0
            summary.commissions = summary.commissions + localCommission;
          }
          if(transData.cgst){
            summary.taxes = summary.taxes + transData.cgst;
          }
          if(transData.sgst){
            summary.taxes = summary.taxes + transData.sgst;
          }
          if(transData.igst){
            summary.taxes = summary.taxes + transData.igst;
          }
          if(transData.cess){
            summary.taxes = summary.taxes + transData.cess;
          }
        }
        //round off to  all summary data 2 decimal places
        summary.grossReceipts = Math.round(summary.grossReceipts*100)/100;
        let netReceipt = summary.grossReceipts - summary.commissions - summary.taxes;
        netReceipt = Math.round(netReceipt*100)/100;

        summary.commissions = Math.round(summary.commissions*100)/100;
        summary.taxes = Math.round(summary.taxes*100)/100;
        setTotalNoOfTransaction(summary.numberOfTransactions);
       setGrossReceipt(summary.grossReceipts);
        setTaxes(summary.taxes);
        setNetReceipt(netReceipt);
        setLoadData(false);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
    }
  }, [loadData]); // eslint-disable-line react-hooks/exhaustive-deps


  let tableHeader = [

      { label: "Booking Type", value: "type" },
      { label: "Sub Category", value: (col) => getSubtypeLabel(col.subtype) },
      {
        label: "Transaction Date",
        value: (col) => convertFirstoreDateForExcel(col.createdDate),
      },
      {
        label: "Transaction Time",
        value: (col) => col.createdTime,
      },

      { 
        label: "Sport", 
      value: (col) => (col.alternativeSport ? col.alternativeSport.text : col.sport ? col.sport.sportName : "") 
    },
      {
        label: "Booking Date",
        value: (col) => (col.bookingDate ? convertFirstoreDateForExcel(col.bookingDate) : ""),
      },
      {
        label: "Slot Name",
        value: (col) => (col.slot ? col.slot.interval ? col.slot.interval.start + '-' + col.slot.interval.end : "" : ""),
      },
      {
        label: "Court Name",
        value: (col) => (col.court ? col.court.courtName : ""),
      },
      { label: "Customer Name", value: "userName" },

      { label: "Amount Paid (in Rs.)", value: "amountPaid" },

      { label: "Booking Source", value: "source" },
      { label: "Booking Sub-Source", value: "bookingSource" },

    { label: "Platform", value: (col) => col.platform ? col.platform : "" },

    { label: "Payment Status", value: (col) => col.paymentStatus ? col.paymentStatus : "" },
    ];


  const onApply = (filters) => {
    setLoadData(true);
    setSelectedFilters(filters);
    setShowFilter(false);
  };

  const openFilter = () => {
    setShowFilter(true);
  };

  const openTransactionDetail = (data) => () => {
    setShowDetails(data);
  };

  const startDate = convertFirstoreDate(
    selectedFilters.startDate,
    "DD MMM YYYY"
  );
  const endDate = convertFirstoreDate(selectedFilters.endDate, "DD MMM YYYY");
  const fileName = 'Transactions ' + startDate + " - " + endDate;
  const sheetName = moment().format('MMMM Do YYYY, h_mm a');

  const sheets = [
    {
      name: sheetName,
      columns: tableHeader,
      dataSet: data,
    },
  ];


  return (
    <div style={{ padding: "16px" }}>
      <Toast
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        message={messageContent.message}
        messageHeader={messageContent.header}
        color={messageContent.color}
      />
      <div style={{ paddingBottom: "16px" }}>
        <div className='dashboard'>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              <span >Transaction Report </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingRight: "10px",
                }}
              >
                ( Range: {startDate}- {endDate})
              </span>
            </div>
            <div style={{ alignItems: "center" }}>
              <span><span>
                <ExportToExcel disabled={data.length === 0 || !(user.role === ROLES.ADMIN || user.role === ROLES.PARTNER  || user.role === ROLES.ACCOUNTANT || user.role === ROLES.AUDITOR)} sheets={sheets}
                  fileName={fileName}
                  buttonName="Download Report" />
              </span>
                <Button onClick={openFilter} name={"filter"}>
                  <Icon
                    color={selectedFilters.isFilterApplied ? "orange" : "grey"}
                    name={"filter"}
                  />
                  Filter
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>

      {showFilter ? (
        <BookingFilters
          onApply={onApply}
          selectedFilters={selectedFilters}
          setLoadData={setLoadData}
          setShowFilter={setShowFilter}
          showFilter
          role={user.role}
        />
      ) : null}
      {!!showDetails ? (
        <TransactionDetails
          selectedData={showDetails}
          setShowDetails={setShowDetails}
          role={user.role}
          setMessage={setShowMessage}
          setMessageContent={setMessageContent}
          isLoading={setLoadData}
        />
      ) : null}
      {loadData ? (
        <GenericLoader />
      ) : data.length === 0 ? (
        "No data available"
      ) : (
        <div>
         <Table celled padded>
            <Table.Header>
              <Table.Row textAlign={"center"} >

                <Table.HeaderCell>Gross Receipts</Table.HeaderCell>

                <Table.HeaderCell>No of Transactions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
          <Table.Row textAlign={"center"}>
              <Table.Cell>
                Rs. {grossReceipts} /-
              </Table.Cell>
              <Table.Cell>
                {totalNoOfTransaction}
              </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date of Transaction</Table.HeaderCell>
                <Table.HeaderCell>Customer Detail</Table.HeaderCell>
                <Table.HeaderCell>Transaction Amount</Table.HeaderCell>
                <Table.HeaderCell>Sport</Table.HeaderCell>
                <Table.HeaderCell>Arena</Table.HeaderCell>
                <Table.HeaderCell>Source</Table.HeaderCell>
                <Table.HeaderCell>Sub-Source</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Created By</Table.HeaderCell>
                <Table.HeaderCell>Transaction ID</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((x) => {
                return (
                  <Table.Row
                    style={
                      x.amountDue ? { backgroundColor: colors.red["50"] } : {}
                    }
                  >
                    <Table.Cell>
                      <div>
                        {convertFirstoreDate(x.createdDate)}, {x.createdTime}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>{x.userName}</div>
                      <div>{!(user.role === ROLES.PARTNER || user.role === ROLES.AUDITOR ) ? x.userMobileNumber : null}</div>
                    </Table.Cell>
                    <Table.Cell>
                      Rs.{x.amountPaid}/-{" "}
                      <div style={{ color: colors.red["300"] }}>
                        {x.amountDue ? "Due Amount: " + x.amountDue : ""}
                      </div>
                    </Table.Cell>
                    
                    <Table.Cell>{x.alternativeSport ? 
                    x.alternativeSport.text 
                    : x.sport? 
                    x.sport.sportName 
                    : x.sportName?  
                    x.sportName.text ? x.sportName.text 
                    : Array.isArray(x.sportName) ? 
                    x.sportName.map(x=>x.sportName+', ')
                    :x.sportName
                    :''}</Table.Cell>
                    <Table.Cell>{x.arenaName || ""}</Table.Cell>
                    <Table.Cell>{getSubtypeLabel(x.source)}</Table.Cell>
                    <Table.Cell>{x.bookingSource}</Table.Cell>
                    <Table.Cell style={{ textTransform: "capitalize" }}>
                      {x.type}, {getSubtypeLabel(x.subtype)}
                      {getBookingStatusLabel(x.bookingStatus)}
                    </Table.Cell>
                    <Table.Cell>{x.createdBy?.userName || "-"}</Table.Cell>
                    <Table.Cell onClick={openTransactionDetail(x)}>
                      <div
                        style={{
                          textDecoration: "underline",
                          color: colors.blue[500],
                        }}
                      >
                        {x.id}
                      </div>
                      {x.rescheduleTo ? (
                        <div style={{ color: "red" }}>Old Slot</div>
                      ) : x.rescheduledFrom ? (
                        <div style={{ color: "red" }}>New Slot</div>
                      ) : x.cancelled ? (
                        <div style={{ color: "grey" }}>Cancelled</div>
                      ) : x.paymentStatus === PAYMENT_STATUS.PENDING && <div style={{ color: "red" }}>Payment Pending</div>}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  );
};

export default InvestorReport;
