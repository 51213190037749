import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
// import Aos from 'aos';
import "aos/dist/aos.css";
import { Fade, Zoom } from 'react-reveal';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const bannerSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down'>PLAY. TRAIN. LIVE.</h4>
        <h1 data-aos='fade-up'>
          Your Favorite Sports In World Class Infrastructure. Play One. Play
          All.
        </h1>
        <div class='btarea' data-aos='zoom-in'>
          <a href='/badminton'>
            <button class='btwhite mr-4'> Badminton </button>
          </a>
          <a href='/cricket-ground'>
            <button class='btwhite mr-4'> Cricket</button>
          </a>

          <a href='/swimming'>
            <button class='btwhite mr-4'> Swimming</button>
          </a>

        </div>
      </div>
    </div>
    <div class='aboutarea '>
      <div class='aboutbox '>
        <div class='boxes '>
          <svg>
            <use href='#park'></use>
          </svg>
          <h5> 7 Cities</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#football'></use>
          </svg>
          <h5> 7 Sports</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#support'></use>
          </svg>
          <h5> 43,000 + Players</h5>
        </div>
      </div>
      
    </div>
  </>
);



const Home = () => {

  const homeScreenData = (
    <>
      <main >
        <section
          id='corporateEvents'
          class='partnersec badmintondeco'
        >
          <div class='container'>
            <div class='innerbx'>
              <div class='imgarea rightbx'>
                <div class='lbx1 lboxst'>
                  <Fade left>
                    <img alt='' src='images/amex.png' />
                  </Fade>
                </div>
                <div class='lbx2 lboxst' >
                  <Fade left>
                    <img alt='' src='images/Nokia.png' />
                  </Fade>
                </div>
                <div class='lbx3 lboxst' >
                  <Fade left>
                    <img alt='' src='images/rbs.png' />
  
                  </Fade>
                </div>
                <div class='lbx4 lboxst' >
                  <Fade left>
                    <img alt='' src='images/gradeup.png' />
  
                  </Fade>
                </div>
                <div class='lbx5 lboxst'>
                  <Fade left>
                    <img alt='' src='images/HCL.png' />
  
                  </Fade>
                </div>
                <div class='lbx6 lboxst' >
                  <Fade left>
                    <img alt='' src='images/Emaar.png' />
  
                  </Fade>
                </div>
                <div class='lbx7 lboxst' >
                  <Fade left>
                    <img alt='' src='images/Huawei.png' />
  
                  </Fade>
                </div>
                <div class='lbx8 lboxst' >
                  <Fade left>
                    <img alt='' src='images/JCPenney.png' />
  
                  </Fade>
                </div>
                <div class='lbx9 lboxst' >
                  <Fade left>
                    <img alt='' src='images/incred.png' />
                  </Fade>
                </div>
                <div class='lbx10 lboxst' >
                  <Fade left>
                    <img alt='' src='images/Karvy.png' />
                  </Fade>
                </div>
                <div class='lbx11 lboxst' >
                  <Fade left>
                    <img alt='' src='images/Mitsubishi.png' />
                  </Fade>
                </div>
                <div class='lbx12 lboxst' >
                  <Fade left>
                    <img alt='' src='images/siemens.png' />
                  </Fade>
                </div>
                <div class='lbx13 lboxst' >
                  <Fade left>
                    <img alt='' src='images/tcs.png' />
                  </Fade>
                </div>
                <div class='lbx14 lboxst' >
                  <Fade left>
                    <img alt='' src='images/KPMG.png' />
                  </Fade>
                </div>
              </div>
              <div class='contentarea '>
                <h4 >END-TO-END CORPORATE EVENTS</h4>
                <h2>Let’s Help You Sport-It</h2>
                <Zoom>
                  <span>
                    We put the “sports” in your busy corporate life. Get your people
                    up and moving with our custom sporting events. From concept to
                    execution, we do the hard work. You{" "}
                    <span class='playallst'>Play All</span>
                  </span></Zoom>
                <div class='btarea'>
                  <a href='/eventsPage'>
                    {" "}
                    <button class='btorng'>Know More</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        <section id='techsection' class=' techsection cricketdeco'>
          <div class='container'>
            <div class='headarea'>
              <h4 class=' sectiontitle ' >
                Tech Solutions
              </h4>
              <h2 class=' sectionhead fadeInDown wow'>
  
                Powering Sports With Technology{" "}
              </h2>
            </div>
            <div class='techarea'>
  
              <div class='techbox ' ><Zoom >
                <h2>Sports Complex Management Software</h2>
              </Zoom>
                <span>
                  Analytics-driven software to simplify sports complex management
                </span>
                <Fade bottom cascade>
                  <img alt='' class='imgstyle' src='images/softwareimg.png' /></Fade>
              </div>
              <div class='techbox ' >
                <Zoom ><h3>App Development</h3></Zoom>
                <span>
                  iOS & Android application design and development for
                  streamlining your sports facilities
                </span>
  
                <Fade bottom cascade>
                  <img alt='' class='imgstyle' src='images/appbg.png' /></Fade>
              </div>
              <div class='techbox1'>
  
                <span>
                  We break the barriers of traditional sports by powering it with
                  technology. Because playing sports is no longer just about fun.
                  It’s about delivering a service that needs flawless management.
                  And, we make this happen with our Tech Solutions.
  
                </span>
                <a href='/techPage'>
                  {" "}
                  <button class='btorng' >
                    Learn More
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>
  
        <section id='consultancysec' class='consultancysec'>
          <div class='consultleft'></div>
          <div class='consultarea cosultancybg'>
            <div class='consultbox floattbox' >
              <h5 class=' sectiontitle '>WE ALSO PROVIDE</h5>
              <Fade left cascade><h4>Infrastructure Consultancy</h4></Fade>
              <Fade bottom>
                <img alt='' class='imgstyle' src='images/consultancybox.png' /></Fade>
  
              <a href='/consultancyPage'>
                {" "}
                <button class='btorng'>Know More</button>
              </a>
            </div>
            <div class='consultbox1'>
              <div class='innerbox'>
                <div class='circlest'>1</div>
  
                <Fade right cascade>
                  <b><h4>Infra Development</h4></b>
                  <span>
                    Leverage our end-to-end sports infra development services to
                    create the sports facility of your dreams
                  </span>
                </Fade>
              </div>
              <div class='innerbox' >
                <div class='circlest'>2</div>
  
                <Fade right cascade>
                  <b><h4>Monetization </h4></b>
                  <span>
                    We help you monetize your existing sports infrastructure and
                    amplify your revenue
                  </span>
                </Fade>
              </div>
              <div class='innerbox' >
                <div class='circlest'>3</div>
  
                <Fade right cascade>
                  <b> <h4>Turnkey Projects</h4></b>
                  <span>
                    From designing and construction to the grand opening, with our
                    turnkey services, we handle it all
                  </span></Fade>
              </div>
            </div>
          </div>
        </section>
      
        <section class=' testimonialsec footballdeco'>
          <div class='container '>
            <div class='headarea'>
              <h4 class=' sectiontitle ' >
                TESTIMONIALS
              </h4>
              <h2 class=' sectionhead '>
                10,000+ Happy Clients
              </h2>
            </div>
            <div class='testimonialarea'>
  
              <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1600} swipeable={true} emulateTouch={true}>
                <div class='testimonbox '>
                  {" "}
                  <img
                    alt=''
                    class='testimonialimg'
                    src='images/shailendrsuyal.png'
                  />
                  <h4>Shailendr Suyal, Noida</h4>
                  <span>
                    Court is too good. One of the best courts if will be
                    maintained properly in near future. Play All is the best Badminton academy near me.
                  </span>
                </div>
  
                <div class='testimonbox '>
                  <img
                    alt=''
                    class='testimonialimg'
                    src='images/richakaushal.png'
                  />
                  <h4>Richa Kaushal, Gurugram</h4>
                  <span>
                    Very nice experience. Professional play. Must visit.
                    Please do come once. Marvellous.
                  </span>
                </div>
  
                <div class='testimonbox '>
                  <img alt='' class='testimonialimg' src='images/ashish.png' />
                  <h4>Ashish Srivastava, Faridabad</h4>
                  <span>
                    Easily accessible sports complex on tigaon road.
                    Coaches are available to guide development of skill
                    sets of all age groups.
                  </span>
                </div>
  
                <div class='testimonbox '>
                  <img alt='' class='testimonialimg' src='images/anubha.png' />
                  <h4>Anubha Sharma, Noida</h4>
                  <span>
                    My kids r doing training for last 3 month. Their front
                    office staff especially Deepak is very impressive in
                    explaining us the offers. Best wishes to Deepak and
                    his team for playall.{" "}
                  </span>
                </div>
  
                <div class='testimonbox '>
                  <img alt='' class='testimonialimg' src='images/ajay.jpg' />
                  <h4>Ajay Kumar, Noida</h4>
                  <span>
                    We have organized one corporate tournament which was
                    really very well with support of management. Best
                    place to play or organis any tournament.
                  </span>
                </div>
  
                <div class='testimonbox '>
                  <img
                    alt=''
                    class='testimonialimg'
                    src='images/jyotirawat.png'
                  />
                  <h4>Jyoti Rawat, Noida</h4>
                  <span>
                    "Great place to play,Friendly environment and great
                    facility..😊👍"{" "}
                  </span>
                </div>
                <div class='testimonbox '>
                  <img
                    alt=''
                    class='testimonialimg'
                    src='images/rahultyagi.png'
                  />
                  <h4>Rahul Tyagi, Noida</h4>
                  <span>
                    great place. around 8 Badminton courts. They have
                    training facility as well. They don't have AC, but
                    ventilation is good and very high roof. You can rent
                    out all equipment and buy some.Has the best Badminton courts near me.
                  </span>
                </div>
  
                <div class='testimonbox '>
                  <img alt='' class='testimonialimg' src='images/akshata.png' />
                  <h4>Akshata Aggarwal, Gurugram</h4>
                  <span>
                    I loved Play All. Amazing indoor badminton court near me,
                    well kept with all basic facilities available. Very
                    hygienic washrooms and changing room. Highly
                    recommended.
                  </span>
                </div>
  
                <div class='testimonbox '>
                  <img
                    alt=''
                    class='testimonialimg'
                    src='images/taranjeet.png'
                  />
                  <h4>Taranjeet Singh, Faridabad</h4>
                  <span>
                    Best experience ever to play here on badminton court &
                    futsal turf near me, they also have table tennis & box
                    cricket with well maintained & hygienic environment,
                    staff is well mannered too.{" "}
                  </span>
                </div>
  
              </Carousel>
            </div>
  
          </div>
        </section>
  
        <section id='gallery' class=' gallerysec'>
          <div class='headarea'>
            <h4 class=' sectiontitle ' >
              Gallery
            </h4>
  
            <Zoom top><h2 class=' sectionhead ' >
              Beautiful Moments
            </h2>
            </Zoom>
          </div>
  
          <div class='galleryarea '>
  
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
  
              <Fade bottom >
                <img alt='' src='images/gallery/sm-1.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              <Fade bottom >
                <img alt='' src='images/gallery/sm-2.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              {" "}
              <Fade bottom >
                <img alt='' src='images/gallery/sm-3.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              {" "}
              <Fade bottom >
                <img alt='' src='images/gallery/sm-4.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              {" "}
              <Fade bottom >
                <img alt='' src='images/gallery/sm-5.png' />
              </Fade>
            </div>
            <div class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'>
              <Fade bottom >
                <img alt='' src='images/gallery/sm-6.png' />
              </Fade>
            </div>
  
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              <Fade bottom >
                <img alt='' src='images/gallery/sm-7.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              <Fade bottom >
                <img alt='' src='images/gallery/sm-8.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              <Fade bottom >
                <img alt='' src='images/gallery/sm-9.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              <Fade bottom >
                <img alt='' src='images/gallery/sm-10.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              {" "}
              <Fade bottom >
                <img alt='' src='images/gallery/sm-11.png' />
              </Fade>
            </div>
            <div
              class='col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2'
            >
              <Fade bottom >
                <img alt='' src='images/gallery/sm-12.png' />
              </Fade>
            </div>
  
          </div>
        </section>
       
      </main>
    </>
  );
  return (
    <>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}

        <div class='headbanner homebg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"home"} />
            {bannerSection}
          </div>
        </div>
        {homeScreenData}
        {footerSection}
       
      </div>

    </>
  );
};

export default Home;
