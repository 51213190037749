import React, { useContext, useEffect,  useState } from 'react';
import {
    Button,
    
    Form, Checkbox
} from "semantic-ui-react";

import { colors } from "@material-ui/core";
import ModalPortal from '../generic/ModalPortal';
import { AppContext } from '../../AppContext';

 
import { createWalletPackage,editWalletPackage } from "../../apiclients/WalletApiClient";

const AddPackages = (props) => {
    const { showModal, setReload, setMessage, isLoading,editPackage,setShowMessage } = props;
    console.log("editPackage",editPackage);
    const context = useContext(AppContext);
    let arenaList = [context.selectedArena];
    const sportList = context?.facilitySports;
    const [advanceBooking, setAdvanceBooking] = useState();
    const [credits, setCredits] = useState();
    const [details, setDetails] = useState();
    const [packageName, setPackageName] = useState();
    const [amount, setAmount] = useState();
    const [hoursPerDay, setHoursPerDay] = useState();
    const [validity, setValidity] = useState();
    const [arenas, setArenas] = useState([context.selectedArena]);
    const [sports, setSports] = useState([]);

    const isSubmitAllowed = advanceBooking && credits && details && packageName && amount && hoursPerDay && validity && arenas.length > 0 && sports.length >0;

    useEffect(() => {
        if(editPackage?.show){
            setAdvanceBooking(editPackage.data.advanceBooking);
            setCredits(editPackage.data.credits);
            setDetails(editPackage.data.details);
            setPackageName(editPackage.data.packageName);
            setAmount(editPackage.data.amount);
            setHoursPerDay(editPackage.data.hoursPerDay);
            setValidity(editPackage.data.validity);
            setArenas(editPackage.data.arenas);
            setSports(editPackage.data.sports);
        }
    },[editPackage])
   
    const updateAllArena = (checked) => {
        if (checked) {
            setArenas(arenaList);
        } else {
            setArenas([]);
        }
    };

    const updateArenas = (obj, arenaId) => {
        if (arenas.some(o => o.arenaId === arenaId)) {
            setArenas(arenas.filter(o => o.arenaId !== arenaId));
        } else {
            setArenas([...arenas, obj]);
        }
    };

    const updateAllSports = (checked) => {
        if (checked) {
            setSports(sportList);
        } else {
            setSports([]);
        }
    };

    const updateSports = (obj, sportId) => {
        if (sports.some(o => o.sportId === sportId)) {
            setSports(sports.filter(o => o.sportId !== sportId));
        } else {
            setSports([...sports, obj]);
        }
    };

  

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>PackageName</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={packageName}
                            placeholder='PackageName'
                            onChange={(evt) => setPackageName(evt.target.value)}
                        />
                    </Form.Field>

                    <Form.Field  >
                        <label>Credits</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={credits}
                            placeholder='Credits'
                            onChange={(evt) => setCredits(evt.target.value)}

                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Amount</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={amount}
                            placeholder='Amount'
                            onChange={(evt) => setAmount(evt.target.value)}

                        />
                    </Form.Field>
                 
                </Form.Group>

                <Form.Group widths='equal'>
                <Form.Field  >
                        <label>Validity</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={validity}
                            placeholder='Validity'
                            onChange={(evt) => setValidity(evt.target.value)}

                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Hours Per Day</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={hoursPerDay}
                            placeholder='Slots Per Day'
                            onChange={(evt) => setHoursPerDay(evt.target.value)}

                        />
                    </Form.Field>
                   
                    <Form.Field  >
                        <label>Advance Booking</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={advanceBooking}
                            placeholder='Advance Booking'
                            onChange={(evt) => setAdvanceBooking(evt.target.value)}

                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths='equal'>

                    <Form.Field  >
                        <label>Details</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={details}
                            placeholder='Details'
                            onChange={(evt) => setDetails(evt.target.value)}

                        />
                    </Form.Field>

                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>
                            Arenas
                            <Checkbox
                                checked={
                                    arenaList.length === arenas.length
                                }
                                style={{ marginRight: "7px", marginLeft: "7px" }}
                                onClick={(e, { checked }) => {
                                    updateAllArena(checked);
                                }}
                            />


                            <span style={{ fontWeight: "lighter" }}> All Arenas </span>
                        </label>
                        {arenaList.map((x) => {
                            const isActive = arenas.some(
                                (mode) => mode.arenaId === x.arenaId
                            );
                            return (
                                <Button
                                    key={x.arenaId}
                                    onClick={() => { updateArenas(x, x.arenaId) }}
                                    style={{
                                        marginRight: "10px",
                                        backgroundColor: isActive
                                            ? colors.orange[700]
                                            : colors.grey[200],
                                        color: isActive ? colors.blue[50] : colors.black,
                                    }}
                                >
                                    {x.arenaName}
                                </Button>
                            );
                        })}

                    </Form.Field>
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>
                            Sports
                            <Checkbox
                                checked={
                                    sportList.length === sports.length
                                }
                                style={{ marginRight: "7px", marginLeft: "7px" }}
                                onClick={(e, { checked }) => {
                                    updateAllSports(checked);
                                }}
                            />


                            <span style={{ fontWeight: "lighter" }}> All Sports </span>
                        </label>
                        {sportList.map((x) => {
                            const isActive = sports.some(
                                (mode) => mode.sportId === x.sportId
                            );
                            return (
                                <Button
                                    key={x.sportId}
                                    onClick={() => { updateSports(x, x.sportId) }}
                                    style={{
                                        marginRight: "10px",
                                        backgroundColor: isActive
                                            ? colors.orange[700]
                                            : colors.grey[200],
                                        color: isActive ? colors.blue[50] : colors.black,
                                    }}
                                >
                                    {x.sportName}
                                </Button>
                            );
                        })}

                    </Form.Field>
                </Form.Group>
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button disabled={!isSubmitAllowed} primary onClick={editPackage?.show?editPackageData:addPackageData}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addPackageData = () => {

        isLoading(true);
        showModal(false);
        const createdBy= {
            userName: context.user.userName,
            userId: context.user.userId,
        };

        const postBody = { 
            createdBy,
            facilityId: context.selectedFacility, 
            arenas, 
            advanceBooking, 
            credits, 
            details, 
            packageName, 
            amount, 
            hoursPerDay,
            sports, 
            validity
        };

        createWalletPackage(
            context.selectedFacility,
            context.selectedArena.arenaId,
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setReload(true)
                setMessage({
                    header: "Package Added",
                    message: "Package has been added successfully.",
                    color: "green",
                });
                setShowMessage(true)
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
                setShowMessage(true)
            }


        isLoading(false);
        setReload(true);

        }).catch((error) => {

            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..


        isLoading(false);
        setReload(true);
        });
    }
    const editPackageData = () => {

        isLoading(true);
        showModal(false);
        const createdBy= {
            userName: context.user.userName,
            userId: context.user.userId,
        };

        const postBody = { 
            walletId: editPackage?.data?.id,
            createdBy,
            facilityId: context.selectedFacility, 
            arenas, 
            advanceBooking, 
            credits, 
            details, 
            packageName, 
            amount, 
            hoursPerDay,
            sports, 
            validity
        };

        editWalletPackage(
            context.selectedFacility,
            context.selectedArena.arenaId,
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setReload(true)
               
                setMessage({
                    header: "Package Edited",
                    message: "Package has been edited successfully.",
                    color: "green",
                });
                
                setTimeout(() => {
                    setShowMessage(false)
                }, 3000);
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
                setShowMessage(true)
            }


        isLoading(false);
        setReload(true);

        }).catch((error) => {

            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..


        isLoading(false);
        setReload(true);
        });
    }
    
    return (
        <>
            <ModalPortal
                title={editPackage?.show?'Edit a Package':'Add a Package'}
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default AddPackages;
