import { tokenAxiosInstance } from "./ApiClient";
import { API_URLS, getURLWithQueryParams } from "./url";

export const getWalletPackages = (facilityId, arenaId,active) => {
    const url = getURLWithQueryParams(API_URLS.GET_WALLET_PACKAGES);
    const body = { facilityId, arenaId, active };
    return tokenAxiosInstance.get(url,{
        params:body
    });
}

export const getWalletDetails = (facilityId, arenaId,) => {
    const url = getURLWithQueryParams(
        API_URLS.GET_WALLET_DETAILS
    );
    const body = { facilityId, arenaId };

    return tokenAxiosInstance.get(url,{
        params:body
    });
}

export const deleteWalletPackage = (facilityId, arenaId,packageId) => {
    const url = getURLWithQueryParams(
        API_URLS.DELETE_WALLET_PACKAGES
    );
    const body = { facilityId, arenaId,id: packageId };

    return tokenAxiosInstance.post(url,body);
}

export const createWalletPackage = (facilityId, arenaId, postBody)=>{
    const url = getURLWithQueryParams(API_URLS.ADD_WALLET_PACKAGES, null, {
        facilityId,
        arenaId,
      });
      return tokenAxiosInstance.post(url, postBody);
}
export const editWalletPackage = (facilityId, arenaId, postBody)=>{
    const url = getURLWithQueryParams(API_URLS.EDIT_WALLET_PACKAGES, null, {
        facilityId,
        arenaId,
      });
      return tokenAxiosInstance.patch(url, postBody);
}

export const disableWalletPackage = (facilityId,id) => {
    const url = getURLWithQueryParams(
        API_URLS.DISABLE_WALLET_PACKAGES
    );
    const body = { facilityId, id };

    return tokenAxiosInstance.post(url,body);
}

export const getUserWalletsDetailsWeb = (facilityId, mobile,expired,arenaId) => {
     
    const url = getURLWithQueryParams(API_URLS.GET_USER_WALLET_DETAILS_WEB);
    const body = { facilityId,mobile,expired,arenaId, };
    return tokenAxiosInstance.get(url,{
        params:body
    });
}

export const getWalletTransactions = (facilityId, mobile,walletId) => {
    
    const url = getURLWithQueryParams(API_URLS.GET_WALLET_TRANSACTION);
    const body = { facilityId,mobile,walletId };
    return tokenAxiosInstance.get(url,{
        params:body
    });
}

export const reduceWalletCredits = (facilityId, arenaId, postBody)=>{
    const url = getURLWithQueryParams(API_URLS.REDUCE_WALLET_CREDITS, null, {
        facilityId,
        arenaId,
      });
      return tokenAxiosInstance.post(url, postBody);
}

export const assignWalletCredits = (facilityId, arenaId, postBody)=>{
    const url = getURLWithQueryParams(API_URLS.ASSIGN_WALLET_CREDITS, null, {
        facilityId,
        arenaId,
      });
      return tokenAxiosInstance.post(url, postBody);
}

export const assignSpecialCredits = (facilityId, arenaId, postBody)=>{
    const url = getURLWithQueryParams(API_URLS.ASSIGN_SPECIAL_CREDITS, null, {
        facilityId,
        arenaId,
      });
      return tokenAxiosInstance.post(url, postBody);
}


//coupons
export const addCoupon = ( postBody)=>{
    const url = getURLWithQueryParams(API_URLS.ADD_COUPON, null, {});
      return tokenAxiosInstance.post(url, postBody);
}
 

export const updateCoupon = ( postBody)=>{
    const url = getURLWithQueryParams(API_URLS.UPDATE_COUPON, null, {});
      return tokenAxiosInstance.patch(url, postBody);
}

export const getAllCoupons = (facilityId, type,) => {
    const url = getURLWithQueryParams( API_URLS.GET_COUPONS);
    const body = { facilityId, type };
    return tokenAxiosInstance.get(url,{
        params:body
    });
}

export const disableCoupon = (facilityId,id) => {
    const url = getURLWithQueryParams(
        API_URLS.DISABLE_COUPON
    );
    const body = { facilityId, couponId:id };
    // console.log("url",url);
    return tokenAxiosInstance.patch(url,body);
}


//banner Management

export const addBanner = ( postBody)=>{
    const url = getURLWithQueryParams(API_URLS.ADD_BANNER, null, {});
    tokenAxiosInstance.defaults.headers.post['Content-Type'] = 'multipart/form-data'
      return tokenAxiosInstance.post(url, postBody);
}

export const updateBanner = ( postBody,id)=>{
    const url = getURLWithQueryParams(API_URLS.UPDATE_BANNER);
      return tokenAxiosInstance.patch(url, postBody);
}

export const getAllBanners = (type) => {
    // console.log("type",type)
    const url = getURLWithQueryParams( API_URLS.GET_BANNER,null,{type:type});
    // console.log("url",url)
    return tokenAxiosInstance.get(url);
}

export const disablebBanner = (id) => {
    const url = getURLWithQueryParams(
        API_URLS.DISABLE_BANNER,null,{id}
    );
    return tokenAxiosInstance.patch(url);
}