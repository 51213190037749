import React, { useState,   } from "react";
import {
  Form,
  
  Button, Table, 
} from "semantic-ui-react";
// import { getLastReceiptNumber } from "../../../../apiclients/DashboardApiClient";
// import Payment from "../../Payment";
// import { colors } from "@material-ui/core";
// import { rentProducts } from "../../../../apiclients/ProductApiClient";
// import { AppContext } from "../../../../AppContext";
// import ProductSelections from "../../ProductSelections";
// import GenericLoader from "../../../generic/GenericLoader";
// import CustomerDetail from "../../../common/CustomerDetail";
import { Typeahead } from "react-bootstrap-typeahead";
import ModalPortal from '../../../generic/ModalPortal';
import CustomModal from "../../../generic/CustomModal";
// import CancelBooking from "../CancelBooking";
import CancelCapacitySportBooking from "./CancelCapacityBooking";
// import RescheduleBooking from "../RescheduleBooking";
import RescheduleCapacityBooking from "./RescheduleCapacityBooking";


const SearchUserDetails = ({
  facility,
  booking,
  setOpen,
  setType,
  reload,
  setMessage,
  userDetails,
  setIsLoading
}) => {
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState(userDetails.userDetails);
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [userData, setUserData] = useState();
  const selectAUser = (user) => {
    if (user.length > 0) {
      setSelectedUser(user);
      setSelectedUserList(user);
    } else {
      setSelectedUser([]);
      setSelectedUserList(userDetails.userDetails);
    }
  }

  const cancelCapacitySportBooking = (data) => {
    setUserData(data);
    setShowCancellationModal(true);
  }

  const rescheduleCapacitySportBooking = (data) => {
    setUserData(data);
    setShowRescheduleModal(true);
  }

  let modalContent = (<>
    <Form>
      <Form.Group widths='equal'>
        <Form.Field>
          <label>Search by Number</label>
          <Typeahead
            id="basic-typeahead-multiple"
            labelKey="userMobileNumber"
            onChange={selectAUser}
            options={userDetails}
            placeholder="Type mobile number..."
            selected={selectedUser}
          />
        </Form.Field>
        <Form.Field>
          <label>Search by Name</label>
          <Typeahead
            id="basic-typeahead-multiple"
            labelKey="userName"
            onChange={selectAUser}
            options={userDetails}
            placeholder="Type Customer Name name..."
            selected={selectedUser}
          />
        </Form.Field>

      </Form.Group>
    </Form>
    <hr />
    <Table celled padded>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>S.No</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Mobile</Table.HeaderCell>
          <Table.HeaderCell># Person</Table.HeaderCell>
          <Table.HeaderCell>Membership</Table.HeaderCell>
          <Table.HeaderCell>Booking Date</Table.HeaderCell>
          <Table.HeaderCell>Source</Table.HeaderCell>
          <Table.HeaderCell>Cancellation</Table.HeaderCell>
          <Table.HeaderCell>Reschedule</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {selectedUserList && selectedUserList.map((x, i) => {
          return (
            <Table.Row key={i}>
              <Table.Cell>{i + 1}</Table.Cell>
              <Table.Cell>{x.userName}</Table.Cell>
              <Table.Cell>{x.userMobileNumber}</Table.Cell>
              <Table.Cell>{x.numberOfCustomer}</Table.Cell>
              <Table.Cell>{x.isMember ? "Yes" : "No"}</Table.Cell>
              <Table.Cell>{new Date(x.createdDate.seconds * 1000).toLocaleDateString()} {new Date(x.createdDate.seconds * 1000).toLocaleTimeString()} </Table.Cell>
              <Table.Cell>{x.source}</Table.Cell>
              <Table.Cell>
                <Button onClick={() => { cancelCapacitySportBooking(x) }} name={"Cancellation"} disabled={x.isMember} >
                  Cancel
                </Button>
              </Table.Cell>
              <Table.Cell>
                <Button onClick={() => { rescheduleCapacitySportBooking(x) }} name={"Reschedule"} disabled={x.isMember} >
                  Reschedule
                </Button>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  </>);

  const renderAction = () => {
    return (
      <React.Fragment>
        <Button onClick={() => setOpen(false)} className='ui button'>Cancel</Button>
      </React.Fragment>
    );
  };


  return (
    <>
     

      {/* <Modal.Content>
        {modalContent}
      </Modal.Content>
      <Modal.Actions>
      </Modal.Actions> */}
      {showCancellationModal ? (
        <ModalPortal
          title="Cancel Booking"
          content={
            <CancelCapacitySportBooking
              bookingDetails={userDetails}
              userData={userData}
              setOpen={setOpen}
              reload={reload}
              setMessage={setMessage}
              setIsLoading={setIsLoading}
              setShowCancellationModal={setShowCancellationModal}
            />
          }
          onDismiss={() => setShowCancellationModal(false)}
        />

      ) : showRescheduleModal ? (
        <ModalPortal
          title="Reschedule Booking"
          content={
            <RescheduleCapacityBooking
              bookingDetails={userDetails}
              userData={userData}
              setOpen={setOpen}
              reload={reload}
              setMessage={setMessage}
              setIsLoading={setIsLoading}
              setShowRescheduleModal={setShowRescheduleModal}
            />
          }
          onDismiss={() => setShowRescheduleModal(false)}
        />

      ) :  <CustomModal
      header={'Search User'}
      content={modalContent}
      footer={renderAction()}
      setOpen={setOpen}
      open={!!modalContent}
    />}
    </>
  );
};

export default SearchUserDetails;
